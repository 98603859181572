<template>
    <div class="myfooter">
        <div class="center">
            <ul class="menu">
                <li v-for="item in menuList" :key="item.name"><a href="javascript:void(0)" @click="gotoPage(item.url)">{{item.name}}</a></li>
            </ul>
            <p class="footer-copy">Copyright 2022 © 筑泰（深圳）信息技术有限公司All Rights Reserved</p>
            <p class="footer-copy"><a href="https://beian.miit.gov.cn/" rel="nofollow" target="_blank">粤ICP备2022036408号</a></p>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            menuList: [{
                name: "产品中心",
                url: "/product"
            },{
                name: "解决方案",
                url: "/solution"
            },{
                name: "服务支持",
                url: "/service"
            },{
                name: "新闻动态",
                url: "/news"
            },{
                name: "关于我们",
                url: "/aboutus"
            }]
        }
    },
    methods: {
		gotoPage(url) {
			if(url) {
				this.$router.push({
					path: url
				});
			}
		},
    }
}
</script>
<style scoped lang="scss">
.myfooter{
    background: #252527;
    overflow: hidden;
    padding: 80px 0 60px 0;
    font-size: 14px;
    .menu{
        display: flex;
        
        li{
            flex: 1;
            text-align: center;
            list-style: none;
            a{
                border-bottom: 1px #FFFFFF solid;
                text-decoration: none;
                color: #FFFFFF;
                line-height: 28px;
                font-size: 16px;
                display: inline-block;
                &:hover {
                    color: #F39800;
                    border-bottom-color: #F39800;
                }
            }
        }
    }

    .footer-copy{
        text-align: center;
        color: #FFFFFF;
        line-height: 30px;
        &:first-of-type{
            margin-top: 140px;
        }
        a {
            text-decoration: none;
            color: #fff;
        }
    }
}
@media only screen and (max-width: 1440px) {
    .myfooter {
        .menu {
            li {
                a {
                    font-size: 14px;
                }
            }
        }
    }
    .footer-copy {
        font-size: 12px;
    }
}
</style>