<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/aboutus/banner_introduce.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">公司介绍{{res1}}</h2>
                <div class="introduce_swiper">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide"><img src="@/assets/aboutus/swiper_introduce_01.png"/></div>
                            <div class="swiper-slide"><img src="@/assets/aboutus/swiper_introduce_02.png"/></div>
                            <div class="swiper-slide"><img src="@/assets/aboutus/swiper_introduce_03.png"/></div>
                        </div>
                    </div>
                </div>
                <p class="smbox_txt introduce_txt" v-html="textFormat(companyInfo.companyDesc)"></p>
            </div>
        </div>
        <div class="introduce_img_box" v-if="companyInfo && companyInfo.companyWish && companyInfo.companyWish !== ''">
            <div class="img_fullwidth">
                <img src="@/assets/aboutus/img_introduce.jpg"/>
            </div>
            <!-- <div class="smbox">
                <div class="wrap_box">
                    <h2 class="smbox_title">企业愿景</h2>
                    <p class="smbox_txt">{{companyInfo.companyWish}}</p>
                </div>
            </div> -->
        </div>
        <div class="wrap_box" style="padding: 155px 0 50px;max-width: 1080px;">
            <video-player class="video-player vjs-custom-skin"
                :playsinline="true"
                :options="playerOptions">
            </video-player>
        </div>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">荣誉资质</h2>
                <div class="introduce_certificate" v-html="companyInfo.companyImg"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Swiper from 'swiper';
import 'swiper/swiper.min.css';
import API from "@/api/index.js";
import { ref } from 'vue'

export default ({
    components: {
        Breadcrumb
    },
    setup() {
        const companyInfo = ref({});

        const loadInfo = async () => {
            let res = await API.home.showCompany({
                code: 'ztxx'
            });
            companyInfo.value = res.content;
        }
        
        return {
            companyInfo,
            loadInfo
        }
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '关于我们',
            }, {
                txt: '公司介绍',
            }],
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4", // 类型
                    src: 'http://zoomtechi.com/ztxx.mp4', // url地址
                }],
                poster: require("@/assets/images/poster.jpg"), // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                },
            },
        }
    },
    mounted() {
        this.loadInfo();

        new Swiper ('.swiper-container', {
            watchSlidesProgress: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: true,
            loopedSlides: 5,
            autoplay: true,
            on: {
                progress: function() {
                    for (let i = 0; i < this.slides.length; i++) {
                        var slide = this.slides.eq(i);
                        var slideProgress = this.slides[i].progress;
                        let modify = 1;
                        if (Math.abs(slideProgress) > 1) {
                            modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                        }
                        let translate = slideProgress * modify * 88 + 'px';
                        let scale = 1 - Math.abs(slideProgress) / 5;
                        let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                        slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                        slide.css('zIndex', zIndex);
                        slide.css('opacity', 1);
                        if (Math.abs(slideProgress) > 3) {
                            slide.css('opacity', 0);
                        }
                    }
                },
                setTransition: function(transition) {
                    for (var i = 0; i < this.slides.length; i++) {
                        var slide = this.slides.eq(i)
                        slide.transition(transition);
                    }
                }
            }
        })
    },
    methods: {
        textFormat(val) {
            // 格式化文字展示文本域格式文字
            if (val) {
                let newString = val.replace(/\n/g, "_@").replace(/\r/g, "_#");
                newString = newString.replace(/_@/g, "<br/>");
                newString = newString.replace(/\s/g, "&nbsp;"); // 空格处理
                return newString;
            }
        },
    },
})
</script>

<style lang="scss" scoped>
.introduce_txt {
    text-align: left;
}
.introduce_img_box {
    position: relative;
    .smbox {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
        display: flex;
        align-items: center;
        .smbox_txt {
            color: #fff;
            font-size: 48px;
            font-weight: bold;
        }
    }
}
.introduce_certificate {
    padding: 90px 0 150px;
    img {
        display: block;
        max-width: 1083px;
        width: 100%;
        margin: 0 auto;
    }
}

.introduce_swiper {
    overflow: hidden;
    padding: 110px 0 70px;
    position: relative;
}

.swiper-slide {
    width: 521px;
    height: 385px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);
    }
    &.swiper-slide-active {
        box-shadow: 0 0 50px #999;
        &::after {
            background-color: transparent;
        }
    }
}
</style>
