<template>
    <div>
        <div class="page_banner">
            <img src="../../assets/product/banner_jinniao.png"/>
        </div>
        <!-- <div class="page_route">
            <div class="page_center">
                您现在的位置：首页/产品中心/晋鸟-警辅通移动安全通信平台 —— 打破边界 沟通世界
            </div>
        </div> -->
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="pro_box">
            <div class="page_center">
                <h2 class="page_title">
                    行业背景
                </h2>
                <p class="content_center">
                          晋鸟-警辅通移动安全通信平台是一款专门为辅警、协警、治安员等外出执勤、移动办公通讯所打造的一款高效安全沟通的产品，<br>
平台支持私有化部署，实现远程安全协作办公。平台以敏感数据为焦点，开发了安全通讯、文件管理、OA办公、考勤打卡、外出巡逻等功能，<br>
实现全应用防截屏、加水印防拍照、数据加密传输等高级别的安全防护，保证数据信息及时、有效、安全地传达，<br>
充分保障执勤辅警、协警等人员通讯的便捷和安全性，避免内部信息泄露。<br>
                </p>
                <ul class="product_icon_list">
                    <li>
                        <img src="../../assets/product/jinniao_icon01.png"/>
                        <p>信息泄露<br>快速追溯</p>
                    </li>
                    <li>
                        <img src="../../assets/product/jinniao_icon02.png"/>
                        <p>文件数据<br>全程加密</p>
                    </li>
                    <li>
                        <img src="../../assets/product/jinniao_icon03.png"/>
                        <p>防第三方<br>窃取传播</p>
                    </li>
                    <li>
                        <img src="../../assets/product/jinniao_icon04.png"/>
                        <p>丰富的<br>互动模块</p>
                    </li>
                    <li>
                        <img src="../../assets/product/jinniao_icon05.png"/>
                        <p>保证通信<br>安全性</p>
                    </li>
                    <li>
                        <img src="../../assets/product/jinniao_icon06.png"/>
                        <p>满足定制<br>化需求</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="pro_box bg_gray">
            <div class="page_center">
                <div class="pro_left">
                    <img src="../../assets/product/pro_jinniao_01.png"/>
                </div>
                <div class="pro_right pro_content">
                    <h3>执勤报备</h3>
                    <p>可运用于外出巡逻、警情上报、隔离点上报及报备等应用场景，上传处警现场照片，实时定位巡警位置，对一线警务人员进行科学化和规范化管理。
    </p>
                </div>
            </div>
        </div>

        <div class="pro_box">
            <div class="page_center">
                <div class="pro_right">
                    <img src="../../assets/product/pro_jinniao_02.png"/>
                </div>
                <div class="pro_left pro_content">
                    <h3>考勤打卡</h3>
                    <p>支持一天4次上岗打卡及上下班打卡，外勤手机随时打卡，可自动上传打卡位置，生成打卡日历和打卡记录，实现巡警移动打卡。
    </p>
                </div>
            </div>
        </div>

        <div class="pro_box bg_gray">
            <div class="page_center">
                <div class="pro_left">
                    <img src="../../assets/product/pro_jinniao_03.png"/>
                </div>
                <div class="pro_right pro_content">
                    <h3>加密通信</h3>
                    <p>满足单聊、群聊的日常通讯，实现阅后即焚、禁止拷贝、转发及截屏的加密聊天，对图片、视频及各类文件进行加密。
    </p>
                </div>
            </div>
        </div>

        <div class="pro_box">
            <div class="page_center">
                <div class="pro_right">
                    <img src="../../assets/product/pro_jinniao_04.png"/>
                </div>
                <div class="pro_left pro_content">
                    <h3>文件管理</h3>
                    <p>采用SM4加密技术对文件进行加密，设置黑白名单限定文件访问人员及文件有效期，全流程追踪文件发送轨迹，支持销毁及撤回。
    </p>
                </div>
            </div>
        </div>

        <div class="pro_box bg_gray">
            <div class="page_center">
                <div class="pro_left">
                    <img src="../../assets/product/pro_jinniao_05.png"/>
                </div>
                <div class="pro_right pro_content">
                    <h3>OA办公</h3>
                    <p>涵盖办公审批、会议管理、通讯管理、流程管理、应用管理、日志管理等多种功能，提高办公效率，降低成本。
    </p>
                </div>
            </div>
        </div>

        <div class="pro_box">
            <div class="page_center">
                <div class="pro_right">
                    <img src="../../assets/product/pro_jinniao_06.png"/>
                </div>
                <div class="pro_left pro_content">
                    <h3>私有化部署</h3>
                    <p>支持功能灵活、安全性高、拓展性强、全面完整的私有化部署方案，提供快速部署、安全应用、开放接口等服务。
    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '产品中心',
            }, {
                txt: '晋鸟-警辅通移动安全通信平台 —— 打破边界 沟通世界',
            }],
        }
    }
}
</script>
<style scoped lang="scss">
// 适配平板
@media screen and (max-width: 1100px) {
    

    
}
</style>
