<template>
    <div>
        <div class="page_banner">
            <img src="../../assets/product/banner_mega10.jpg"/>
        </div>
        <!-- <div class="page_route">
            <div class="page_center">
                您现在的位置：首页/产品中心/筑泰天骐Mega 10安全手机 —— 天之骐骥 与你同翔
            </div>
        </div> -->
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="pro_box">
            <div class="page_center">
                <h2 class="page_title">行业背景</h2>
                <p class="content_center">
                    筑泰携手海信共同打造的一款行业定制双系统安全手机，致力于创行业安全手机领导品牌。<br>
                    其搭载的行业深度定制双系统将工作和个人生活完全隔离成两个安卓系统，既能保证工作内容信息安全，又能保留丰富便捷的生活应用。<br>
                    筑泰天骐在拥有强大的MDM能力及覆盖全国的海信通信服务网点售后保障体系的同时兼具极高性价比。<br>
                </p>
            </div>
        </div>

        <div class="pro_box bg_black">
            <div class="page_center mega_iconbox">
                <ul class="product_icon_list">
                    <li>
                        <img src="../../assets/product/mega10_icon01.png"/>
                        <p>安全双系统</p>
                    </li>
                    <li>
                        <img src="../../assets/product/mega10_icon02.png"/>
                        <p>5000mAh大电池</p>
                    </li>
                    <li>
                        <img src="../../assets/product/mega10_icon03.png"/>
                        <p>支持NFC功能</p>
                    </li>
                    <li>
                        <img src="../../assets/product/mega10_icon04.png"/>
                        <p>后置三摄</p>
                    </li>
                    <li>
                        <img src="../../assets/product/mega10_icon05.png"/>
                        <p>大存储空间</p>
                    </li>
                    <li>
                        <img src="../../assets/product/mega10_icon06.png"/>
                        <p>丰富的定制能力</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="mega_item">
            <img src="@/assets/product/pro_mega10_01.png"/>
            <div class="mega_item_wrap">
                <div class="wrap_box">
                    <div class="mega_item_txt right">
                        <h3 class="pro_mega_title mega_item_title">安全双系统 <span>一触即达</span></h3>
                        <p>工作系统和生活系统一键切换，工作、生活互不影响。</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mega_item bg_gray">
            <img src="@/assets/product/pro_mega10_02.png"/>
            <div class="mega_item_wrap">
                <div class="wrap_box">
                    <div class="mega_item_txt">
                        <h3 class="pro_mega_title mega_item_title">5000mAh充沛电量</h3>
                        <p>搭载5000mAh大电池，18W快速充电，超长续航，保证全天候充沛电量。</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mega_item">
            <img src="@/assets/product/pro_mega10_03.png"/>
            <div class="mega_item_wrap mega_itembox3_info">
                <div class="pro_mega_title mega_title">后置三摄 超清影像</div>
                <p class="mega_info">
                    搭载后置4800万、200万独立景深（红外）、200万微距三摄镜头，800万前置摄像头，<br>
                    具备超级夜景、视频防抖功能，打造超清
                </p>
                <div class="mega_pixel mega_center">
                    <p class="pro_mega_title">4800万</p>
                    <p>搭载后置4800万、200万独立景深（红外）</p>
                </div>
                <div class="mega_pixel mega_left">
                    <p class="pro_mega_title">800万</p>
                    <p>前置摄像头</p>
                </div>
                <div class="mega_pixel mega_right">
                    <p class="pro_mega_title">200万</p>
                    <p>微距三摄镜头</p>
                </div>
            </div>
        </div>

        <div class="mega_item">
            <img src="@/assets/product/pro_mega10_04.png"/>
            <div class="mega_item_wrap mega_itembox4_info">
                <span class="pro_mega_title">支持<span class="mega_center">NFC</span>功能</span>
            </div>
        </div>

        <div class="mega_item">
            <img src="@/assets/product/pro_mega10_05.jpg"/>
            <div class="mega_item_wrap">
                <div class="wrap_box">
                    <div class="mega_item_txt">
                        <h3 class="pro_mega_title mega_item_title">大存储空间</h3>
                        <p style="padding: 20px 0 80px;">可选8+128GB、8+256GB两种大存储空间型号，能确保手机有足够空间来存放聊天记录、<br/>照片视频等文件，无需担心内存告急。</p>
                        <div class="mega_itembox5_info">
                            <span class="pro_mega_title">8+128GB</span>
                            <span class="pro_mega_title">8+256GB</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mega_item">
            <img src="@/assets/product/pro_mega10_06.jpg"/>
            <div class="mega_item_wrap">
                <div class="wrap_box mega_itembox6_info">
                    <div class="mega_item_txt">
                        <h3 class="pro_mega_title mega_item_title">高清全面屏 观感更舒适</h3>
                        <p>6.18英寸FHD+2400*1080高清全面屏，享受更舒适的观感体验。</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mega_item bg_gray">
            <img src="@/assets/product/pro_mega10_07.jpg"/>
            <div class="mega_item_wrap">
                <div class="wrap_box mega_itembox7_info">
                    <div class="mega_item_txt">
                        <h3 class="pro_mega_title mega_item_title">丰富的定制能力</h3>
                        <p>提供开机动画、开机LOGO、铃声、桌面壁纸、锁屏壁纸等个性化定制。</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="pro_box">
            <div class="page_center">
                <h2 class="page_title black">产品参数</h2>
            </div>
            <div class="pro_data">
                <table cellpadding="0" cellspacing="0">
                    <tr>
                        <td>显示屏</td>
                        <td>6.81英寸，FHD+2400*1080像素</td>
                    </tr>
                    <tr>
                        <td>CPU处理器</td>
                        <td>高通SM4350，8nm，SOC，2A76@2.0GHZ+6A55@1.8GHZ</td>
                    </tr>
                    <tr>
                        <td>操作系统</td>
                        <td>Android R</td>
                    </tr>
                    <tr>
                        <td>存储</td>
                        <td>8+128G/8+256G</td>
                    </tr>
                    <tr>
                        <td rowspan="2">摄像头</td>
                        <td>后置：4800万+200万红外/测距+200万微距</td>
                    </tr>
                    <tr>
                        <td>前置：1600万</td>
                    </tr>
                    <tr>
                        <td>卫星定位</td>
                        <td>GPS，GLONASS.北斗；支持双频定位</td>
                    </tr>
                    <tr>
                        <td>NFC</td>
                        <td>支持</td>
                    </tr>
                    <tr>
                        <td>电池</td>
                        <td>5000mAh快速充电</td>
                    </tr>
                    <tr>
                        <td>充电器</td>
                        <td>18W快充</td>
                    </tr>
                    <tr>
                        <td>耳机</td>
                        <td>不支持3.5mm耳机，支持TPYE-C耳机</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '产品中心',
            }, {
                txt: '筑泰天骐Mega 10安全手机 —— 天之骐骥 与你同翔',
            }],
        }
    }
}
</script>

<style lang="scss" scoped>
.mega_iconbox {
    .product_icon_list {
        display: flex;
        margin: 0;
        padding: 90px 0 70px;
        li {
            padding: 0 5px;
            flex: 1;
            width: auto;
            float: none;
        }
    }
}
.page_title {
    &.black {
        -webkit-text-fill-color: #000;
    }
}
.pro_data {
    table {
        margin: 60px auto 50px;
        tr {
            td {
                border-color: #000;
                &:last-child {
                    padding-left: 13%;
                }
            }
        }
    }
}

.mega_item {
    position: relative;
    min-height: 260px;
    img {
        display: block;
        width: 100%;
    }
    .mega_item_wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .wrap_box {
            height: 100%;
            display: flex;
            align-items: center;
            &.mega_itembox6_info {
                align-items: center;
                text-align: center;
                justify-content: center;
                position: absolute;
                height: 50%;
                top: 55%;
                right: 0;
                left: 0;
                padding-top: 90px;
                .mega_item_txt {
                    p {
                        color: #fff;
                    }
                    .mega_item_title {
                        margin: 0 0 20px;
                    }
                }
            }
        }
        .mega_item_txt {
            &.right {
                padding-left: 52%;
            }
            p {
                font-size: 16px;
                color: #000;
                line-height: 2;
            }
            .mega_item_title {
                font-size: 28px;
                font-weight: bold;
                margin: 0 0 20px;
                &.big {
                    font-size: 60px;
                }
                span {
                    font-weight: normal;
                }
            }
        }
    }
}

.pro_mega_title {
    background: linear-gradient(0deg, #D65D00 0%, #F39800 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1;
}
.mega_itembox5_info {
    font-size: 28px;
    font-weight: bold;
    .pro_mega_title {
        display: inline-block;
        padding-right: 14%;
        &:last-child {
            padding-right: 0;
        }
    }
}
.mega_itembox4_info {
    .pro_mega_title {
        font-size: 28px;
        position: absolute;
        left: 0;
        top: 26%;
        right: 0;
        text-align: center;
        .mega_center {
            font-size: 28px;
        }
    }
}
.mega_itembox3_info {
    .mega_title {
        font-size: 28px;
        text-align: center;
        font-weight: bold;
        position: absolute;
        top: 16%;
        width: 100%;
        text-align: center;
    }
    .mega_info {
        font-size: 16px;
        text-align: center;
        line-height: 2;
        position: absolute;
        top: 25%;
        width: 100%;
        text-align: center;
    }
    .mega_pixel {
        text-align: center;
        font-size: 16px;
        position: absolute;
        .pro_mega_title {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }
    .mega_center {
        width: 100%;
        top: 42%;
        text-align: center;
    }
    .mega_left {
        top: 55%;
        right: 50%;
        margin-right: 13%;
    }
    .mega_right {
        top: 55%;
        left: 50%;
        margin-left: 13%;
    }
}

.mega_itembox7_info {
    .mega_item_txt {
        position: absolute;
        top: 23%;
        left: 0;
        right: 0;
        text-align: center;
    }
}

// 适配平板
@media screen and (max-width: 1100px) {
    .pro_box{
        .mega_iconbox{
            .product_icon_list{
                padding: 60px 0;
                li{
                    img{
                        width: 60px;
                    }
                    p{
                        font-size: 16px;
                        margin-top: 20px;
                    }
                }
            }
        }
    
  }

  .mega_item{
      .mega_item_wrap{
          .mega_item_txt{
              .mega_item_title{
                  font-size: 20px;
              }
              p{
                  font-size: 14px;
              }
          }
      }

      .mega_itembox3_info{
          .mega_info{
              font-size: 14px;
              line-height: 20px;
          }
          .mega_title{
              font-size: 20px;
              
          }
          .mega_pixel{
              .pro_mega_title{
                  font-size: 20px;
                  margin-bottom: 10px;
              }
          }
      }

      .mega_itembox4_info{
          .pro_mega_title{
              font-size: 26px;
              .mega_center{
                  font-size: 26px;
              }
          }
      }
  }
}
</style>

