<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/news/banner_newsDetail.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="wrap_box">
            <h1 class="news_detail_title">{{detail.newsTitle}}</h1>
            <div class="news_detail_info">
                <span>发布时间：{{detail.updateTime}}</span>
                <span>作者：{{detail.createUser}}</span>
                <span>浏览量：{{detail.readCount ? detail.readCount: 0}}</span>
            </div>
            <div class="news_detail_content" v-html="detail.newsContent"></div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import API from "@/api/index.js";
import { ref } from 'vue'
import { useRoute } from 'vue-router'

export default ({
    components: {
        Breadcrumb
    },
    setup() {
        const detail = ref({});
        let route = useRoute();

        let readCount = async () => {
            await API.home.readCount({
                id: route.query.id,
                type: '1'
            });
        }

        let loadDetail = async () => {
            let res = await API.home.shownewsDetail({
                id: route.query.id
            });
            detail.value = res.content
        }
        
        return {
            detail,
            readCount,
            loadDetail
        }
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '新闻动态',
                url: '/news/index'
            }]
        }
    },
    mounted() {
        this.readCount();
        this.loadDetail();
    }
})
</script>

<style lang="scss" scoped>
.news_detail_title {
    color: #000;
    font-weight: bold;
    font-size: 28px;
    line-height: 1.5;
    text-align: center;
    padding-top: 60px;
}
.news_detail_info {
    color: #727070;
    font-size: 14px;
    line-height: 2;
    text-align: right;
    padding: 20px 0 10px;
    span {
        margin-left: 40px;
    }
}
.news_detail_content {
    border-top: 1px solid #ececec;
    color: #000;
    font-size: 16px;
    line-height: 2;
    padding: 40px 0 160px;
    img {
        width: 100%;
    }
}
</style>
