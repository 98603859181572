<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/solution/banner_patrol.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox" style="padding-bottom: 150px;">
            <div class="wrap_box">
                <h2 class="smbox_title">行业背景</h2>
                <div class="solution_img">
                    <img src="@/assets/solution/img_patrol.jpg"/>
                </div>
                <p class="smbox_txt left">对于建筑、轨道、电力、大型设备的安全、可靠、稳定、经济运行是其赖以生存和发展的基础。大型设施中任意设备的缺陷故障，都有可能影响事故的发生。而传统巡检设备冗杂、效率低下、容易出现漏查、评估不准确、数据孤立、缺乏关联性等问题。</p>
            </div>
        </div>
        <div class="smbox bg_black" style="padding-top: 50px;padding-bottom: 80px;">
            <div class="wrap_box">
                <h2 class="smbox_title">方案概述</h2>
                <p class="smbox_txt left" style="color: #fff;padding-top: 60px;">将增强现实、人工智能、头戴设备等新技术结合企业实际需要可以很好的改变目前的巡检状况，应用之后大大提高巡检效率，避免了巡检人员的缺口 ，确保电力系统更加稳定运行，并且更加进一步得推动了巡检工作标准化、管理的科学智能化、监督的自动化。</p>
            </div>
        </div>
        <div class="smbox" style="padding-bottom: 0;">
            <h2 class="smbox_title">解决方案</h2>
            <div class="solution_item_box">
                <div class="solution_item_02 bg_gray">
                    <div class="wrap_box">
                        <img src="@/assets/solution/bg_patrol_01.jpg"/>
                        <div class="solution_item_txt">
                            <p class="solution_item_title">远程指导</p>
                            <p>释放双手，智能眼镜完全解放了巡检人员双手，</p>
                            <p>特别是高空作业是，使其集中尽力完成任务，通过数模对比技术，将数据、设备、</p>
                            <p>工具等数字模型导入后台数据，高效比对设备信息。</p>
                        </div>
                    </div>
                </div>
                <div class="solution_item_02 reverse">
                    <div class="wrap_box">
                        <img src="@/assets/solution/bg_patrol_02.jpg"/>
                        <div class="solution_item_txt">
                            <p class="solution_item_title">巡检记录</p>
                            <p>巡检拍照打点并同步至后台，语音转文本存储至后台。</p>
                        </div>
                    </div>
                </div>
                <div class="solution_item_02 bg_gray">
                    <div class="wrap_box">
                        <img src="@/assets/solution/bg_patrol_03.jpg"/>
                        <div class="solution_item_txt">
                            <p class="solution_item_title">操作手册可视化</p>
                            <p>通过后台将现有的标准化内容，转化成可视化巡检资料，传递给智能眼镜终端，</p>
                            <p>实时指导巡检人员标准化完成巡检工作。</p>
                        </div>
                    </div>
                </div>
                <div class="solution_item_02 reverse">
                    <div class="wrap_box">
                        <img src="@/assets/solution/bg_patrol_04.jpg"/>
                        <div class="solution_item_txt">
                            <p class="solution_item_title">远程AR协助</p>
                            <p>当巡检员遇到困难时，可以通过调动AR眼镜摄像头以第一视角对现场进行观察，</p>
                            <p>远程专家指导处理棘手问题，极大减少沟通和交流成本。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
export default ({
    components: {
        Breadcrumb
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '解决方案',
            }, {
                txt: '智慧巡检行业解决方案'
            }]
        }
    }
})
</script>
