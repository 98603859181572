<template>
    <div class="header">
        <div class="h_center">
            <img class="logo" src="../assets/logo.png"  @click="gotoPage('/')" />
            <ul class="menu">
                <li v-for="item in menuList" :key="item.name">
                    <a href="javascript:void(0)" @click="gotoPage(item.url)">{{item.name}}</a>
                    <ul class="menu_child" v-if="item.childs && item.childs.length">
                        <li v-for="item2 in item.childs" :key="item2.name"><a href="javascript:void(0)" @click="gotoPage(item2.url)">{{item2.name}}</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return {
            menuList: [{
                name: "首页",
                url: "/"
            },{
                name: "产品中心",
                childs: [{
                    name: "筑泰天骐安全手机",
                    url: "/product/mega10"
                },{
                    name: "筑视AR智能眼镜",
                    url: "/product/arGlass"
                },{
                    name: "泰极宽窄带融合终端",
                    url: "/product/beijia"
                },{
                    name: "晋鸟移动安全通信平台",
                    url: "/product/jinniao"
                },{
                    name: "移动安全终端管控平台",
                    url: "/product/emm"
                }]
            },{
                name: "解决方案",
                childs: [{
                    name: "智慧公安",
                    url: "/solution/police"
                }, {
                    name: "智慧司法",
                    url: "/solution/judicial"
                }, {
                    name: "智能制造",
                    url: "/solution/make"
                }, {
                    name: "智慧巡检",
                    url: "/solution/patrol"
                }]
            },{
                name: "服务支持",
                url: "/service"
            },{
                name: "新闻动态",
                url: "/news"
            },{
                name: "关于我们",
                childs: [{
                    name: "公司介绍",
                    url: "/aboutus/introduce"
                }, {
                    name: "联系我们",
                    url: "/aboutus/contactus"
                }, {
                    name: "加入我们",
                    url: "/aboutus/joinus"
                }]
            }]
        }
    },
    methods: {
		gotoPage(url) {
			if(url) {
                console.log(url)
				this.$router.push({
					path: url
				});
			}
		},
    }
}
</script>
<style scoped lang="scss">
.header{
    .h_center{
        max-width: 1440px;
        margin: 0 auto;
    }
    height: 90px;
    background: #000000;
    // padding: 0 120px 0 75px;
    .logo{
        width: 168px;
        margin-top: 22px;
        cursor: pointer;
    }
    .menu{
        float: right;
        &>li{
            float: left;
            list-style: none;
            line-height: 90px;
            padding: 0 40px;
            position: relative;
            a{
                color: #ffffff;
                text-decoration: none;
                line-height: 30px;
                font-size: 16px;
            }
            &::after{
                content: " ";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #ffffff;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 43px;
            }
            &:last-child::after{
                display: none;
            }
            &:hover .menu_child{
                display: block;
            }
            .menu_child{
                position: absolute;
                left: 0;
                z-index: 100;
                display: none;
                li{
                    width: 180px;
                    height: 54px;
                    background: #000000;
                    list-style: none;
                    a{
                        color: #ffffff;
                        text-decoration: none;
                        float: left;
                        line-height: 54px;
                        width: 100%;
                        text-align: center;
                        font-size: 14px;
                    }
                    &:hover{
                        background: #545454;
                    }
                }
            }
            &:hover{
                &>a{
                    color: #F39800;
                    // border-bottom: 2px #F39800 solid;
                    position: relative;
                    &::after{
                        content: " ";
                        width: 100%;
                        height: 2px;
                        background: #F39800;
                        display: block;
                        position: absolute;
                        top: 26px;
                    }
                }
                .menu_child{
                    display: block;
                }
            }
            
        }
    }
}

// @media only screen and (max-width: 1440px) {
//     .header {
//         .logo {
//             width: 150px;
//             margin-left: 50px;
//         }
//         .menu {
//             &>li {
//                 a {
//                     font-size: 12px;
//                 }
//             }
//         }
//     }
// }

// 适配平板
@media screen and (max-width: 1100px) {
    .header {
        .logo{
            width: 140px;
        }
        .menu > li{
            padding: 0 20px;
            
            a{
                font-size: 14px;
            }
        }

    }
}

// 适配手机端
@media screen and (max-width: 750px) {
    .header .menu{
        display: none;
    }
}
</style>
