<template>
    <Transition name="down">
        <div class="my-message-wrap">
            <div class="my-message" :style="style[type]" v-show='isShow'>{{text}}</div>
        </div>
    </Transition>
</template>

<script>
import { onMounted, ref } from 'vue'
export default {
    name: 'myMessage',
    props: {
        text: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            // warn 警告  error 错误  success 成功
            default: 'warn'
        }
    },
    setup () {
        // 定义一个对象，包含三种情况的样式，对象key就是类型字符串
        const style = {
            warn: {
                color: '#E6A23C',
                backgroundColor: 'rgb(253, 246, 236)',
                borderColor: 'rgb(250, 236, 216)'
            },
            error: {
                color: '#F56C6C',
                backgroundColor: 'rgb(254, 240, 240)',
                borderColor: 'rgb(253, 226, 226)'
            },
            success: {
                color: '#67C23A',
                backgroundColor: 'rgb(240, 249, 235)',
                borderColor: 'rgb(225, 243, 216)'
            }
        }
        // 控制动画
        const isShow = ref(false)
        // 组件模板渲染成功后触发
        onMounted(() => {
            isShow.value = true
        })
        return { style, isShow }
    }
}
</script>

<style lang="scss" scope>
.down {
    &-enter {
        &-from {
            transform: translate3d(0, -75px, 0);
            opacity: 0;
        }
            &-active {
            transition: all 0.5s;
        }
        &-to {
            transform: none;
            opacity: 1;
        }
    }
}
.my-message-wrap {
    position: fixed;
    top: 20px;
    left: 0;
    z-index: 9999;
    width: 100%;
    pointer-events: none;
    text-align: center;
    .my-message {
        display: inline-block;
        padding: 8px 25px;
        border: 1px solid #e4e4e4;
        background: #f5f5f5;
        color: #999;
        border-radius: 6px;
    }
}
</style>