<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/solution/banner_judicial.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox" style="padding-bottom: 150px;">
            <div class="wrap_box">
                <h2 class="smbox_title">行业背景</h2>
                <div class="solution_img">
                    <img src="@/assets/solution/img_judicial.jpg"/>
                </div>
                <p class="smbox_txt left">为深入实施创新驱动发展战略，大力推进“数字法治、智慧司法”科技创新工程，围绕“五年三步走”“智慧监狱”建设目标，充分运用云计算、物联网、大数据等先进理念和技术手段打造智慧监狱综合执法和指挥调度平台，为监狱管理和公共服务提供有力支持，充分强化监狱安防指挥能力，提升监狱整体管理水平，打造高效的警务运行机制，充分展现监狱现代治理能力。</p>
            </div>
        </div>
        <div class="smbox bg_black" style="padding: 40px 0 50px;">
            <div class="wrap_box">
                <h2 class="smbox_title">应用场景</h2>
                <ul class="solution_icon_list">
                    <li>
                        <img src="@/assets/solution/icon_judicial_01.png"/>
                        <p>监区点名</p>
                    </li>
                    <li>
                        <img src="@/assets/solution/icon_judicial_02.png"/>
                        <p>警情处置</p>
                    </li>
                    <li>
                        <img src="@/assets/solution/icon_judicial_03.png"/>
                        <p>日常巡逻</p>
                    </li>
                    <li>
                        <img src="@/assets/solution/icon_judicial_04.png"/>
                        <p>门禁联动</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">方案概述</h2>
                <p class="smbox_txt left">监区内执勤民警可以轻松进行每天的点名任务，只需要佩戴AR智能眼镜、多功能融合终端、安全手机，轻松走过点名人员就可以即时完成点名任务，点名结果自动上传后台并形成报表，结果一目了然。执勤中的执法音频，视频都可以自动上传到后台，出监区时存储的音视频自动删除。出现突发状况时，执勤民警第一时间把现场音视频传输到指挥中心，中心领导根据现场音视频进行快速指挥和调度，现场人员能够及时，正确处理突发事件，保证监区的安全。</p>
            </div>
        </div>
        <div class="smbox" style="padding-bottom: 0;">
            <h2 class="smbox_title">解决方案</h2>
            <div class="solution_item_box">
                <div class="solution_item">
                    <img src="@/assets/solution/bg_judicial_01.jpg"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt right">
                                <p class="solution_item_title">自动点名</p>
                                <p>执勤民警佩戴AR智能眼镜，先选择点名场景，再通过智能识别进行点名，</p>
                                <p>点名过程中可以进行多人协同点名，点名数据实时同步，结束点名后显示结果，</p>
                                <p>未到人员可标注去向信息。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_item">
                    <img src="@/assets/solution/bg_judicial_02.jpg"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt">
                                <p class="solution_item_title">执法记录</p>
                                <p>执勤人员通过AR智能眼镜第一视角拍摄执法全过程，</p>
                                <p>可直接存储在双网智能终端上，也可以通过5G/4G网络直接传输到指挥中心。</p>
                                <p>可实时将视频直接转发到领导佩戴的AR眼镜直接显示，</p>
                                <p>执法记录能保证执法过程合规、可追溯。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_item">
                    <img src="@/assets/solution/bg_judicial_03.jpg"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt right">
                                <p class="solution_item_title">指挥调度</p>
                                <p>利用5G双网智能终端网络传输、存储和实时对讲功能，</p>
                                <p>将AR智能眼睛采集到的信息实时传到指挥中心，指挥中心针对现场情况进行研判，</p>
                                <p>快速做出指挥决策。通过5G双网智能终端实现执勤现场和指挥中心的互通互联，</p>
                                <p>提高处置效率。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_item">
                    <img src="@/assets/solution/bg_judicial_04.jpg"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt">
                                <p class="solution_item_title">移动办公</p>
                                <p>监区移动办公将安全手机切换至执勤模式，将点名或监区日常数据实时上传到后台，</p>
                                <p>实现安全便捷办公。并结合NFC功能实现出入监区的空间切换，</p>
                                <p>实现安全办公的同时灵活应用安全手机。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
export default ({
    components: {
        Breadcrumb
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '解决方案',
            }, {
                txt: '智慧司法行业解决方案'
            }]
        }
    }
})
</script>
