<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/solution/banner_police.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox" style="padding-bottom: 150px;">
            <div class="wrap_box">
                <h2 class="smbox_title">行业背景</h2>
                <div class="solution_img">
                    <img src="@/assets/solution/img_police.jpg"/>
                </div>
                <p class="smbox_txt">智慧公安建设需要紧跟互联网时代的发展脚步，创新公安行业应用，应该基于互联网、物联网、云计算、智能引擎、视频技术、数据挖掘、知识管理等技术，<br/>以公安信息化为核心，通过互联化、物联化、智能化的方式，促进公安系统各个功能模块高度集成、协调运作，实现警务信息“强度整合、高度共享、深度应用”的目标。</p>
            </div>
        </div>
        <div class="smbox bg_blue" style="padding: 40px 0 50px;">
            <div class="wrap_box">
                <h2 class="smbox_title">应用场景</h2>
                <ul class="solution_icon_list">
                    <li>
                        <img src="@/assets/solution/icon_police_01.png"/>
                        <p>日常执勤巡逻</p>
                    </li>
                    <li>
                        <img src="@/assets/solution/icon_police_02.png"/>
                        <p>车辆、人员信息排查</p>
                    </li>
                    <li>
                        <img src="@/assets/solution/icon_police_03.png"/>
                        <p>罪犯现场抓捕</p>
                    </li>
                    <li>
                        <img src="@/assets/solution/icon_police_04.png"/>
                        <p>紧急警情处理</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="smbox" style="padding-bottom: 0;">
            <h2 class="smbox_title">解决方案</h2>
            <div class="solution_item_box">
                <div class="solution_item">
                    <img src="@/assets/solution/bg_police_01.jpg"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt right">
                                <p class="solution_item_title">重点人员管控</p>
                                <p>使用人脸识别技术对高危人员识别、布控，信访人员识别、管控，</p>
                                <p>地方不稳定人员识别，外籍人员识别管控，大型活动安保人员识别、管控等。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_item">
                    <img src="@/assets/solution/bg_police_02.png"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt">
                                <p class="solution_item_title">车辆排查</p>
                                <p>交警执勤或重点区域出入口使用车牌识别技术对车辆管理全面排查，</p>
                                <p>实现来往车辆视频抓取监测，交警通过后台数据比对，</p>
                                <p>快速识别违规车辆，并进行记录和布控，出入口处快速识别正常车辆和非法车辆。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_item">
                    <img src="@/assets/solution/bg_police_03.png"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt right">
                                <p class="solution_item_title">指挥调度</p>
                                <p>警员将现场画面以第一人称视角实时传输到指挥中心，</p>
                                <p>指挥中心领导和现场警员通过文字、语音、视频、定位等多种方式调度一线警员，</p>
                                <p>帮助执法警员与指挥中心无缝对接，高效处置现场突发状况。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_item" style="width: 100%;padding-bottom: 32%;min-height: auto;position: relative;">
                    <!-- <img src="@/assets/solution/bg_police_04.gif"/> -->
                    <video style="position: absolute;width: 100%;height: 100%;object-fit: cover;" playsinline x5-video-player-type="h5" muted autoplay loop :src="videoUrl"></video>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt">
                                <p class="solution_item_title">安全办公</p>
                                <p>方案通过筑泰移动安全手机，</p>
                                <p>一方面在安全系统下安全办公；另外一方面满足AR视频及图片网络传输要求。</p>
                                <p>形成一个完整的、多维的移动办公闭环。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
export default ({
    components: {
        Breadcrumb
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '解决方案',
            }, {
                txt: '智慧公安行业解决方案'
            }],
            videoUrl: require("@/assets/solution/bg_police_04.mp4")
        }
    }
})
</script>
