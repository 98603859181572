import { createApp } from 'vue'
import App from './App.vue'
import "./styles/normalize.css"
import "./styles/style.scss"
import router from "./router/index"
import Message from './common/message.js'
// import './common/rem.js'
import VideoPlayer from "vue-video-player/src";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";

// 跳转后返回顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

const app = createApp(App);
app.config.globalProperties.baseUrl = 'http://120.78.162.185:8080';
app.config.globalProperties.$message = Message;

app.use(router).use(VideoPlayer).mount('#app')
