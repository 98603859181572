import qs from 'qs';
import axios from "axios";
import message from "@/common/message.js"

// 请求拦截
axios.interceptors.request.use(config => {
	if(config.headers["Content-Type"] !== "multipart/form-data") {
		let data = qs.parse(config.data);
		config.data = data;
	}
	return config;
}, err => {
	return Promise.reject(err);
});

// 拦截响应response，并做一些错误处理
axios.interceptors.response.use(response => {
	return response;
}, err => {
	// 这里是返回状态码不为200时候的错误处理
	if (err && err.response) {
		message({ text: err.message, type: 'error' });
	}
	return Promise.reject(err);
});

export default {
	baseURL: (() => {   // 判断是在生产环境还是开发环境，如果是生产环境baseURL为/api
		if(process.env.NODE_ENV === "development") {
			return '/api'
		} else {
			return "http://120.78.162.185:18010"
		}
	})(),
	ajaxs(getUrl, params, methods) {
		return new Promise((resolve, reject) => {
			let data = Object.assign({}, params);
			let axiosParams = {
				url: getUrl,
				baseURL: this.baseURL,
				method: methods ? methods : "POST",
				headers: {
					"Content-Type": "application/json"
				}
			}
			//添加参数
			if (methods === 'GET') {
				Object.defineProperty(axiosParams, 'params', {
					value: data,
					enumerable: true
				})
			} else {
				Object.defineProperty(axiosParams, 'data', {
					value: data,
					enumerable: true
				})
			}
			axios(axiosParams).then(res => {
				if (res.data.code === 200) {
					resolve(res.data);
				} else {
					message({ text: res.data.msg, type: 'error' });
					reject(res.data);
				}
			}, err => {
				message({ text: '服务器异常', type: 'error' });
				reject(err);
			}).catch(err => {
				reject(new Error(err));
			});
		});
	},
};