import {createRouter, createWebHashHistory} from "vue-router";
import Index from "../pages/Index/index.vue"

import ProductEmm from "../pages/Product/emm.vue"
import ProductJinniao from "../pages/Product/jinniao.vue"
import ProductBeijia from "../pages/Product/beijia.vue"
import ProductArGlass from "../pages/Product/arGlass.vue"
import ProductMega10 from "../pages/Product/mega10.vue"

import Service from "../pages/Service/index.vue"

import AboutusIntroduce from "../pages/Aboutus/introduce.vue"
import AboutusContactus from "../pages/Aboutus/contactus.vue"
import AboutusJoinus from "../pages/Aboutus/joinus.vue"

import SolutionPolice from "../pages/Solution/police.vue"
import SolutionJudicial from "../pages/Solution/judicial.vue"
import SolutionMake from "../pages/Solution/make.vue"
import SolutionPatrol from "../pages/Solution/patrol.vue"

import News from "../pages/News/index.vue"
import NewsDetail from "../pages/News/detail.vue"

const routes = [
    { path: '/', component: Index },

    { path: '/product', component: ProductMega10 },
    { path: '/product/emm', component: ProductEmm },
    { path: '/product/jinniao', component: ProductJinniao },
    { path: '/product/beijia', component: ProductBeijia },
    { path: '/product/arGlass', component: ProductArGlass },
    { path: '/product/mega10', component: ProductMega10 },

    { path: '/service', component: Service },

    { path: '/aboutus', component: AboutusIntroduce },
    { path: '/aboutus/introduce', component: AboutusIntroduce },
    { path: '/aboutus/contactus', component: AboutusContactus },
    { path: '/aboutus/joinus', component: AboutusJoinus },

    { path: '/solution', component: SolutionPolice },
    { path: '/solution/police', component: SolutionPolice },
    { path: '/solution/judicial', component: SolutionJudicial },
    { path: '/solution/make', component: SolutionMake },
    { path: '/solution/patrol', component: SolutionPatrol },

    { path: '/news', component: News },
    { path: '/news/index', component: News },
    { path: '/news/detail', component: NewsDetail },
  ]

const router = createRouter({
    history: createWebHashHistory(),
    routes // routes: routes 的简写
})

export default router; 