<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/news/banner_news.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">新闻动态</h2>
                <div>
                    <ul class="news_box">
                        <li class="news_item" v-for="(item, index) in newsList" :key="index">
                            <div class="news_img" @click="gotoPage(item.id)" :style="{backgroundImage: 'url(' + baseUrl + item.newsImg + ')'}"></div>
                            <div class="news_info">
                                <p class="news_title">{{item.newsTitle}}</p>
                                <p class="news_intro">{{item.newsTips}}</p>
                                <span class="news_btn" @click="gotoPage(item.id)">查看详情</span>
                            </div>
                        </li>
                    </ul>
                    <div class="news-pagination">
                        <Pagination @change-page='changePage' :pageSize='reqParams.pageSize' :total='total' :pageNo='1' />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, watch } from "vue";
import Breadcrumb from '@/components/Breadcrumb.vue'
import Pagination from "@/components/Pagination.vue";
import API from "@/api/index.js";
import { ref, getCurrentInstance } from 'vue'

export default ({
    components: {
        Breadcrumb,
        Pagination
    },
    setup() {
        // 筛选条件准备
        const reqParams = reactive({
            // 当前页码
            pageNo: 1,
            // 每页的条数
            pageSize: 5,
        })
        const total = ref(0);
        const newsList = ref([]);
        // 侦听参数的变化
        watch(reqParams, async () => {
            let res = await API.home.shownews({
                pageNo: reqParams.pageNo-1,
                pageSize: reqParams.pageSize,
                
            });
            total.value = res.totalElements;
            newsList.value = res.content;
        }, {
            immediate: true
        })
        // 控制页码的变化
        const changePage = (pageNo) => {
            // 修改分页参数，重新调用接口即可
            reqParams.pageNo = pageNo
        }

        const internalInstance = getCurrentInstance();
        const baseUrl = internalInstance.appContext.config.globalProperties.baseUrl;

        return {
            reqParams,
            total,
            newsList,
            baseUrl,
            changePage,
        }
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '新闻动态',
            }],
        }
    },
    methods: {
		gotoPage(id) {
            this.$router.push({
                path: '/news/detail',
                query: {
                    id
                }
            });
		}
	}
})
</script>

<style lang="scss" scoped>
.news_box {
    .news_item {
        display: flex;
        align-items: center;
        padding: 75px 0 80px;
        border-bottom: 2px dashed #B9C5D4;
        .news_img {
            margin-right: 106px;
            cursor: pointer;
            width: 27%;
            padding-top: 17%;
            border-radius: 5px;
            background-size: cover;
            background-position: center;
        }
        .news_info {
            flex: 1;
            min-width: 0;
            p {
                color: #000;
                line-height: 2;
            }
            .news_title {
                font-size: 24px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .news_intro {
                font-size: 14px;
                margin: 20px 0 25px;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .news_btn {
                display: inline-block;
                line-height: 1.5;
                color: #F39800;
                font-size: 14px;
                padding: 6px 28px;
                border: 2px solid #E68C02;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                    background-color: rgba(243,152,0,.1);
                }
            }
        }
    }
}
.news-pagination {
    padding-top: 40px;
}
</style>
