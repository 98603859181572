<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/solution/banner_make.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox" style="padding-bottom: 150px;">
            <div class="wrap_box">
                <h2 class="smbox_title">行业背景</h2>
                <div class="solution_img">
                    <img src="@/assets/solution/img_make.jpg"/>
                </div>
                <p class="smbox_txt left">制造业中传统人工目检存在工作复杂、工作量大、误差大，耗时费力的问题，不完全的检测数据和图片收集为后期数据分析带来很大的难度。尤其是对精密设备的安装和检测的过程中，检修人员需要参照大量的资料并具备一定的检修经验，但智能制造业的保密性要求非常高，对于图纸资料具有一定的现在，这大大降低了检修人员的工作效率。</p>
            </div>
        </div>
        <div class="smbox bg_black" style="padding-top: 50px;padding-bottom: 60px;">
            <div class="wrap_box">
                <h2 class="smbox_title">方案概述</h2>
                <p class="smbox_txt left" style="color: #fff;padding-top: 74px;">方案对精密设备中的部件进行远程协助、远程检修、诊断和维修，减少人工操作带来误差，同时解放双手，提高准确度和工作效率。在精密设备部件的安装、检修等过程中，对核心部件建立数字模型，通过计算机视觉技术，通过AR眼镜的高精度摄像头，将实物与对应的数字模型进行比对，从而完成精准安装、远程指导检修和维护等。</p>
            </div>
        </div>
        <div class="smbox" style="padding-bottom: 0;">
            <h2 class="smbox_title">解决方案</h2>
            <div class="solution_item_box">
                <div class="solution_item">
                    <img src="@/assets/solution/bg_make_01.png"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt right">
                                <p class="solution_item_title">远程指导</p>
                                <p>当现场人员遇到无法用本地化支撑解决问题时，可利用远程专家协作系统，</p>
                                <p>通过总部专家进行业务指导，通过AR终端分享第一人称视角，</p>
                                <p>让远程专家快速感知现场，这样可降低高达70%的支持差旅费用，</p>
                                <p>减少5倍人员出差频次，提升80%的服务和培训效率，极大提高故障的处置效率。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_item">
                    <img src="@/assets/solution/bg_make_02.png"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt">
                                <p class="solution_item_title">AR知识库</p>
                                <p>AR知识库可集成各类图纸、音视频交互方式，随时调取查看，</p>
                                <p>并支持放大、缩小，锁定画面等功能，保证看清楚每一处细节，灵活安全，</p>
                                <p>保证信息和数据安全。AR眼镜可以真正释放一线员工的双手，</p>
                                <p>无需在造作设备时翻阅纸质操作手册，</p>
                                <p>减少携带大量纸质文件的工作，同时避免大量文件遗失损坏问题。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_item">
                    <img src="@/assets/solution/bg_make_03.jpg"/>
                    <div class="solution_item_wrap">
                        <div class="wrap_box">
                            <div class="solution_item_txt right">
                                <p class="solution_item_title">技术培训</p>
                                <p>新员工在高度沉浸化的学习内容中能够快速学习和建立标准化的工作流程，</p>
                                <p>加快新员工独立开展业务的能力。</p>
                                <p>可集合不同地域专家同时在平台内为新员工进行赋能，</p>
                                <p>迅速协作一线建立服务能力，并为企业降本增效。AR平台的多人协作培训系统，</p>
                                <p>可实现专家通过第一视角展示新设备、新技能的培训分享。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
export default ({
    components: {
        Breadcrumb
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '解决方案',
            }, {
                txt: '智能制造行业解决方案'
            }]
        }
    }
})
</script>

