<template>
	<div class="bg_black_breadcrumb" v-if="list.length > 0">
		<div class="wrap_box_1440">
			<div class="breadcrumb">
				<span>您现在的位置：</span>
				<div v-for="(item, index) in list" :key="index">
					<span @click="gotoPage(item)" :class="{'link': item.url}">{{item.txt}}</span>
					<span v-if="(index+1) !== list.length" class="breadcrumb_next">/</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default() {
				return []
			}
		},
	},
	methods: {
		gotoPage(item) {
			if(item.url) {
				this.$router.push({
					path: item.url
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {

}
</style>
