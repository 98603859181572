<template>
    <div>
        <div class="page_banner">
            <img src="../../assets/product/banner_ar.jpg"/>
        </div>
        <!-- <div class="page_route">
            <div class="page_center">
                您现在的位置：首页/产品中心/筑视ZT30 AR智能眼镜 —— 窥探本源 预视未来
            </div>
        </div> -->
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>

        <div class="pro_box bg_black pro_box_01">
            <div class="page_center">
                <h2 class="page_title">
                    行业背景
                </h2>
                <p class="content_center">
                          筑视 ZT30 AR智能眼镜是一款采用视觉边缘计算技术，集智能识别、PDT对讲、多媒体指挥调度等功能于一体的智能通信产品。<br>
产品基于人体工程学设计，整体重量不超过80g。产品基于阵列光波导技术打造了1.5mm超薄波导镜片，眼镜透光度可达82%；配置了800万像素自动对焦相<br>机及1W喇叭和MIC，拥有4K专业级摄像能力，1920*1080P高清录像，可满足不同场景的多样化需求。
                </p>
                
                
            </div>
        </div>

        <div class="pro_box bg_black pro_box_02">
            <div class="page_center">
                <div class="pro_left">
                    <img src="../../assets/product/pro_ar_01.png"/>
                </div>
                <div class="pro_right pro_content">
                    <h3>AR智能识别 从此解放双手</h3>
                    <p>依托视觉边缘计算技术，AR智能眼镜对人脸和车辆信息实时采集、上传并分析，结果即刻呈现在AR眼镜上，<br>
    全程无感完成，快速实现人脸和车辆的信息核验。
    </p>
                </div>
            </div>
            
        </div>

        <div class="pro_box bg_black pro_box_03">
            <div class="page_center" style="display: flex;align-items: center;">
                <div class="pro_content" style="flex: 1;">
                    <h3 style="margin: 0;">PDT专网对讲 稳定抗干扰</h3>
                    <p style="margin-top: 30px;line-height: 3;">通过连接多功能融合终端产品,结合数字语音压缩技术和硬件降噪技术，<br>
                    实现语音清晰、高效频谱效率、 通信距离远、抗干扰能力强等特点，在各种嘈杂、 <br>
                    复杂的环境下仍能清晰通话。
                    </p>
                </div>
                <div style="width: 39%;">
                    <img src="../../assets/product/pro_ar_02.jpg" style="display: block;width: 100%;" />
                </div>
            </div>
            
        </div>

        <div class="pro_box pro_box_04">
            <div class="page_center">
                <div class="pro_content">
                    <h3>第一视角呈现 快速指挥调度</h3>
                    <p>AR融合指挥调度平台通过将前端智能眼镜与后台指挥中心快速联动，<br>将音视频画面实时传送至指挥中心，<br>辅助其快速决策、部署警力，实现人员、车辆调度及路线规划。</p>
                </div>
                <div class="pro_img">
                    <img src="../../assets/product/pro_ar_03.png"/>
                </div>
            </div>
        </div>


        <div class="pro_box_lbg pro_box05">
            <img class="pro_bg" src="../../assets/product/pro_ar_04.jpg"/>
        </div>

        <div class="pro_box bg_black pro_box_06">
            <ul>
                <li>
                    <div class="pro_icon">
                        <img src="../../assets/product/ar_icon01.png"/>
                    </div>
                    <h4>800W</h4>
                    <p>自动对焦摄像头</p>
                </li>
                <li>
                    <div class="pro_icon">
                        <img src="../../assets/product/ar_icon02.png"/>
                    </div>
                    <h4>1080P</h4>
                    <p>超高清视频</p>
                </li>
                <li>
                    <div class="pro_icon">
                        <img src="../../assets/product/ar_icon03.png"/>
                    </div>
                    <h4>82%</h4>
                    <p>高透过率</p>
                </li>
                <li>
                    <div class="pro_icon">
                        <img src="../../assets/product/ar_icon04.png"/>
                    </div>
                    <h4>约80g</h4>
                    <p>头戴重量</p>
                </li>
                <li>
                    <div class="pro_icon">
                        <img src="../../assets/product/ar_icon05.png"/>
                    </div>
                    <h4>1.55mm</h4>
                    <p>超薄波导镜片</p>
                </li>
                <li>
                    <div class="pro_icon">
                        <img src="../../assets/product/ar_icon06.png"/>
                    </div>
                    <h4>IP66</h4>
                    <p>防护等级</p>
                </li>
            </ul>
            <div class="pro_content pro_content_center">
                <h3>执法记录取证 减轻执勤负担</h3>
                <p>4K专业级摄像能力，1920*1080P超高清视频现场取证，支持录音录像和拍照功能，可实时上传或保存视频，满足日常执法取证、<br>
交通执法、纠纷记录、城管执法等场景。
                        </p>
            </div>
        </div>


        <div class="pro_box pro_box_07">
            <div class="page_center">
                <div class="pro_left">
                    <img src="../../assets/product/pro_ar_05.png"/>
                </div>
                <div class="pro_left pro_content">
                    <h3>前端图像收集 平台数据分析</h3>
                    <p>采用视觉边缘计算技术和AI信息识别算法，通过前端AR眼镜收集、发送图像，连接相关行业应用大数据平台，回传分析结果，形成收集、分析、回传成像的闭环。</p>
                </div>
            </div>
        </div>

        <div class="pro_box_lbg pro_box08">
            <img class="pro_bg" src="../../assets/product/pro_ar_06.jpg"/>
        </div>


        

        <div class="pro_box pro_box08 bg_black">
            <div class="pro_content pro_content_center">
                <h3>人体工学设计 佩戴轻巧舒适</h3>
                <p>镜片采用光波导技术，具有轻薄、高穿透性、高清成像等特点，单目设计有效缓解视觉疲劳；整体重量不足80g，<br>
轻便易携带，符合人体工程学设计原理，佩戴舒适，无眩晕感。
                        </p>
            </div>  
            <div class="pro_data">
                <h3>产品参数</h3>
                <table cellpadding="0" cellspacing="0">
                    <tr>
                        <td>重量</td>
                        <td>约80g</td>
                    </tr>
                    <tr>
                        <td>防护等级</td>
                        <td>IP66</td>
                    </tr>
                    <tr>
                        <td>使用环境</td>
                        <td>全天候</td>
                    </tr>
                    <tr>
                        <td>穿戴方式</td>
                        <td>眼镜佩戴</td>
                    </tr>
                    <tr>
                        <td>视场角</td>
                        <td>32°</td>
                    </tr>
                    <tr>
                        <td>分辨率</td>
                        <td>1280*720</td>
                    </tr>
                    <tr>
                        <td>主摄像头像素</td>
                        <td>800W</td>
                    </tr>
                    <tr>
                        <td>交互方式</td>
                        <td>实体按键</td>
                    </tr>
                    <tr>
                        <td>扬声器</td>
                        <td>双喇叭+三麦</td>
                    </tr>
                    <tr>
                        <td>传感器</td>
                        <td>三合一光感</td>
                    </tr>
                </table>
            </div>   
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '产品中心',
            }, {
                txt: '筑视ZT30 AR智能眼镜 —— 窥探本源 预视未来',
            }],
        }
    }
}
</script>

<style scoped lang="scss">

.pro_content{
    h3{
        font-size: 24px;
        &::before{
            display: none;
        }
    }
    p{
        font-size: 14px;
    }
}
.pro_box_02{
    padding: 0;
    margin-top: -230px;
    .pro_right{
        width: 865px;
        margin-left: -200px;
        margin-top: -150px;
        text-align: right;
    }
}
.pro_box_03{
    padding-top: 140px;
    .pro_content{
        width: 570px;
        h3{
            margin-top: 230px;
        }
    }
}

.pro_box_04{
    background: #fff;
    padding: 0;
    .page_center {
        display: flex;
        align-items: center;
        max-width: 1440px;
        width: 100%;
        .pro_img {
            padding-top: 100px;
            width: 44%;
            img {
                display: block;
                width: 100%;
            }
        }
        .pro_content {
            flex: 1;
            padding-left: 6%;
            h3 {
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }
}
.pro_box_06{
    ul{
        width: 1040px;
        margin: 0 auto;
        overflow: hidden;
        li{
            float: left;
            width: 33.33%;
            text-align: center;
            list-style: none;
            margin-top: 100px;
            h4{
                font-size: 26px;
                background-image:-webkit-linear-gradient(bottom,#D65D00,#F39800); 
                -webkit-background-clip:text; 
                -webkit-text-fill-color:transparent; 
                margin-bottom: 0;
                margin-top: 20px;
                line-height: 1.5;
            }
            .pro_icon{
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            p{
                font-size: 14px;
                margin-top: 20px;
            }
        }
    }
    .pro_content{
        margin-top: 150px;
    }
}

.pro_box_07{
    background: #EEEEEE;
    overflow: hidden;
    display: flex;
    padding-bottom: 0;
    padding-top: 0;
    .page_center {
        max-width: 1440px;
        width: 100%;
        display: flex;
        .pro_left{
            width: 50%;
            img{
                display: block;
                width: 100%;
                margin-top: 130px;
            }
        }
        .pro_content{
            padding-left: 5%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h3{
                margin: 0;
            }
            p {
                margin-top: 30px;
            }
        }
    }
}

.pro_box08{
    table{
        tr{
            td{
                width: 400px;
                text-align: center;
                padding: 0;
            }
        }
        
    }
}

// 适配平板
@media screen and (max-width: 1100px) {
    .pro_box_06{
        ul{
            li{
                h4{
                    font-size: 20px;
                    margin-top: 10px;
                }
                p{
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>