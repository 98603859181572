import Ajax from "./Fecth"

export default {
    home: {
        saveVisitorn(params) {   // 来访信息记录
            return Ajax.ajaxs("/home/saveVisitorn", params)
        },
        readCount(params) {   // 计算浏览量
            return Ajax.ajaxs("/home/readCount", params)
        },
        showCompany(params) {   // 查看公司信息
            return Ajax.ajaxs("/home/showCompany", params)
        },
        shownews(params) {   // 展示新闻列表
            return Ajax.ajaxs("/home/shownews", params)
        },
        shownewsDetail(params) {   // 查看单个新闻内容
            return Ajax.ajaxs("/home/shownewsDetail", params)
        },
        showRecruitInfo(params) {   // 展示简历
            return Ajax.ajaxs("/home/showRecruitInfo", params)
        },
    },
}
