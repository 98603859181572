<template>
    <div>
        <!-- banner -->
        <div class="banner swiper-no-swiping">
            <swiper :style="{ height: swiperHeight + 'px' }" :speed="1000" :autoplay="{delay: 5000,disableOnInteraction: false}" :modules="modules" @swiper="setControlledSwiper" @slideChange="slideChange">
                <swiper-slide>
                    <div class="swiper-video">
                        <video ref="videoPlayer" playsinline x5-video-player-type="h5" muted autoplay :src="videoUrl" @ended="videoEnded"></video>
                        <div class="swiper-video-txt">
                            <p class="swiper-video-title">融汇科技力量 预见智慧未来</p>
                            <p>专业的移动安全和AR行业解决方案服务商</p>
                            <button @click="changeVideoFull()">点击查看完整视频</button>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide v-for="(item, index) in bannerList" :key="index">
                    <a href="javascript:void(0)" @click="gotoPage(item.url)">
                        <img :src="item.path" />
                    </a>
                </swiper-slide>
            </swiper>
            <div class="banner-btnbox banner-prev"><div class="banner-btn" @click="changeSwiper(-1)"></div></div>
            <div class="banner-btnbox banner-next"><div class="banner-btn" @click="changeSwiper(1)"></div></div>
        </div>

        <!-- 产品展示 -->
        <div class="box">
            <div class="center">
                <h2>PRODUCT DISPLAY</h2>
                <h1>产品展示</h1>
                <ul class="product-list" ref="testref">
                    <li>
                        <div class="product-list-box" @click="gotoPage('/product/arGlass')">
                            <img src="../../assets/images/index-pro-01.png" />
                            <div :class="imgShowFlag ? '' : 'hide'">
                                <p class="product-list-title" :class="imgShowFlag ? 'animate__animated animate__fadeInDownBig' : ''">筑视<span>AR</span>智能眼镜</p>
                                <p class="product-list-label" :class="imgShowFlag ? 'animate__animated animate__fadeInDownBig' : ''">AR智能识别 解放双手</p>
                                <p :class="imgShowFlag ? 'animate__animated animate__fadeInDownBig' : ''">一款采用视觉边缘计算技术，集智能识别、PDT对讲、多媒体指挥调度等功能于一体的智能通信产品。</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="product-list-box" @click="gotoPage('/product/mega10')">
                            <img src="../../assets/images/index-pro-02.jpg" />
                            <div :class="imgShowFlag ? '' : 'hide'">
                                <p class="product-list-title" :class="imgShowFlag ? 'animate__animated animate__fadeInDownBig' : ''">筑泰<span>天骐</span>安全手机</p>
                                <p class="product-list-label" :class="imgShowFlag ? 'animate__animated animate__fadeInDownBig' : ''">安全双系统 支持NFC</p>
                                <p :class="imgShowFlag ? 'animate__animated animate__fadeInDownBig' : ''">筑泰携手海信共同打造的一款行业定制双系统安全手机，致力于创行业安全手机领导品牌。</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 解决方案 -->
        <div class="box">
            <div class="center">
                <h2>SOLUTION</h2>
                <h1>解决方案</h1>
                <ul class="solution-list">
                    <li>
                        <div class="solution-item" @click="gotoPage('/solution/police')">
                            <img src="../../assets/images/index-solution-01.jpg" />
                            <div class="solution-item-bg"></div>
                            <div class="solution-item-box">
                                <p class="solution-item-title">智慧公安</p>
                                <p>高效办公 赋能智慧公安数字化建设</p>
                                <span class="solution-item-btn">了解更多 <span>&gt;</span></span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="solution-item" @click="gotoPage('/solution/judicial')">
                            <img src="../../assets/images/index-solution-02.jpg" />
                            <div class="solution-item-bg"></div>
                            <div class="solution-item-box">
                                <p class="solution-item-title">智慧司法</p>
                                <p>智能执法 开启智慧司法新时代</p>
                                <span class="solution-item-btn">了解更多 <span>&gt;</span></span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="solution-item" @click="gotoPage('/solution/make')">
                            <img src="../../assets/images/index-solution-03.jpg" />
                            <div class="solution-item-bg"></div>
                            <div class="solution-item-box">
                                <p class="solution-item-title">智能制造</p>
                                <p>AR来袭 助力智能制造变革与突破</p>
                                <span class="solution-item-btn">了解更多 <span>&gt;</span></span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="solution-item" @click="gotoPage('/solution/patrol')">
                            <img src="../../assets/images/index-solution-04.jpg" />
                            <div class="solution-item-bg"></div>
                            <div class="solution-item-box">
                                <p class="solution-item-title">智慧巡检</p>
                                <p>人工智能  推动巡检标准化与科学化</p>
                                <span class="solution-item-btn" >了解更多 <span>&gt;</span></span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 新闻动态 -->
        <div class="box box1">
            <div class="center">
                <h2>NEW INFORMATION</h2>
                <h1>新闻动态</h1>
                <ul class="new-list">
                    <li v-for="(item, index) in newsList" :key="index">
                        <div class="news-item" @click="gotoPage('/news/detail', {id: item.id})">
                            <div class="news-img" :style="{backgroundImage: 'url(' + baseUrl + item.newsImg + ')'}"></div>
                            <p>{{item.newsTitle}}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 公司简介 -->
        <div class="box company-box" style="padding-bottom: 0;">
            <div class="center">
                <h2>COMPANY INTRODUTION</h2>
                <h1>公司简介</h1>
                <div class="company-div" style="margin-top: 40px;">
                    <p class="company-text">
                        {{companyInfo.companyDesc}}
                    </p>
                    <button class="company-btn" @click="gotoPage('/aboutus/introduce')">了解更多</button>
                </div>
            </div>
        </div>
    </div>
    <div class="videoPlayer" :style="{display: isVideoFull ? 'flex' : 'none'}">
        <div class="videoPlayer-in">
            <video ref="videoPlayerFull" controls x5-video-player-type="h5" :src="videoUrlFull"></video>
            <div class="videoPlayer-btn" @click="changeVideoFull()"></div>
        </div>
    </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// Import Swiper styles
import 'swiper/swiper.min.css';
import "swiper/modules/pagination/pagination.min.css";
import { Pagination, Autoplay } from "swiper";
import API from "@/api/index.js";
import { ref, getCurrentInstance } from 'vue'
import "animate.css"

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const swiperHeight = ref(0);
        const setSwiperHeight = () => {
            swiperHeight.value = document.documentElement.clientWidth >= 1440 ? (document.documentElement.clientHeight - 90) : (document.documentElement.clientWidth * 820 / 1920);
        }

        const controlledSwiper = ref({});
        const setControlledSwiper = (swiper) => {
            controlledSwiper.value = swiper;
            swiper.autoplay.stop();
        }

        const companyInfo = ref({});
        const loadInfo = async () => {
            let res = await API.home.showCompany({
                code: 'ztxx'
            });
            companyInfo.value = res.content;
        }

        const newsList = ref([]);
        const loadNews = async () => {
            let res = await API.home.shownews({
                pageNo: 0,
                pageSize: 3,
            });
            newsList.value = res.content;
        }

        const internalInstance = getCurrentInstance();
        const baseUrl = internalInstance.appContext.config.globalProperties.baseUrl;

        return {
            modules: [Pagination, Autoplay],
            swiperHeight,
            setSwiperHeight,
            companyInfo,
            loadInfo,
            newsList,
            loadNews,
            baseUrl,
            controlledSwiper,
            setControlledSwiper,
        };
    },
    data(){
        return {
            bannerList: [{
                path: require("../../assets/banner/banner01.jpg"),
                url: "/product/beijia"
            }, {
                path: require("../../assets/banner/banner02.jpg"),
                url: "/product/arGlass"
            }, {
                path: require("../../assets/banner/banner03.jpg"),
                url: "/product/mega10"
            }],
            imgShowFlag: false,
            videoUrl: require("@/assets/video/swiper-video.mp4"),
            videoUrlFull: 'http://zoomtechi.com/ztxx.mp4',
            isVideoFull: false
        }
    },
    mounted() {
        this.setSwiperHeight();
        this.loadInfo();
        this.loadNews();
        window.addEventListener("scroll", this.handleScroll);
        window.onresize = () => {
            //自适应大小
            this.setSwiperHeight();
        };
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
		gotoPage(url, value) {
			if(url) {
                let param = {
                    path: url
                }
                if(value) {
                    param.query = value;
                }
				this.$router.push(param);
			}
		},
        handleScroll() {
            // 滚动位置
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // 获取窗口高度
            var domHight = document.documentElement.clientHeight;
            // 获取监听元素本身高度
            var scrollHeight;
            // 获取监听元素距离页面顶部距离
            var offsetTop;
            // 获取监听元素距离顶部高度-窗口高度 
            var top;
            // 元素距离底部的高度+元素本身高度
            var bottom;
            
            let testref = this.$refs.testref;
            scrollHeight = testref.scrollHeight;
            offsetTop = testref.offsetTop;
            top = offsetTop - domHight > 0 ? offsetTop - domHight : 0;
            bottom = scrollHeight + offsetTop;

            // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
            if (scrollTop >= top && scrollTop <= bottom) {
                this.imgShowFlag = true;
            } else {
                this.imgShowFlag = false;
            }
        },
        changeSwiper(type) {
            let swiperDom = this.controlledSwiper;
            let index = swiperDom.activeIndex;
            if(index === 3 && type === 1) {
                swiperDom.slideTo(0);
            } else if(index === 0 && type === -1) {
                swiperDom.slideTo(3);
            } else {
                type === 1 ? swiperDom.slideNext() : swiperDom.slidePrev();
            }
        },
        videoEnded() {
            this.changeSwiper(1);
        },
        slideChange(swiper) {
            let index = swiper.activeIndex;
            if(index === 0) {
                this.$refs.videoPlayer.play();
                this.controlledSwiper.autoplay.stop();
            } else  {
                this.$refs.videoPlayer.pause();
                this.controlledSwiper.autoplay.start();
            }
        },
        changeVideoFull() {
            if(this.isVideoFull) {
                this.isVideoFull = false;
                this.$refs.videoPlayer.play();
                this.$refs.videoPlayerFull.pause();
            } else {
                this.isVideoFull = true;
                this.$refs.videoPlayer.pause();
                this.$refs.videoPlayerFull.play();
            }
        }
    }
}
</script>
<style lang="scss" scope>
    .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

    .box{
        background: #252527;
        padding: 100px 0 150px;
        
        h1, h2{
            text-align: center;
        }
        h1{
            color: #F39800;
            font-size: 50px;
            position: relative;
            top: -80px;
            letter-spacing: 10px;
        }
        h2{
            font-size: 25px;
            color: #6A6A6A;
            font-weight: 400;
            letter-spacing: 20px;
            font-style: italic;
        }
    }

    .product-list {
        overflow: hidden;
        margin: 0 -10px;
        li{
            float: left;
            list-style: none;
            width: 50%;
            padding: 0 10px;
            .product-list-box {
                cursor: pointer;
                position: relative;
                img {
                    display: block;
                    width: 100%;
                }
                div {
                    position: absolute;
                    top: 11%;
                    left: 21%;
                    right: 21%;
                    opacity: 1;
                    p {
                        color: #FFFEFE;
                        font-size: 14px;
                        text-align: center;
                        font-weight: lighter;
                        line-height: 2;
                        animation-duration: 1.5s;
                        &.product-list-label {
                            font-size: 16px;
                            font-weight: normal;
                            padding-bottom: 25px;
                            animation-duration: 2s;
                        }
                        &.product-list-title {
                            font-size: 32px;
                            letter-spacing: 2px;
                            font-weight: normal;
                            animation-duration: 2.5s;
                            span {
                                color: #F39800;
                                font-size: 32px;
                            }
                        }
                    }
                    &.hide {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .solution-list{
        overflow: hidden;
        margin: 0 -10px;
        li{
            float: left;
            list-style: none;
            width: 25%;
            padding: 0 12px;
            .solution-item {
                position: relative;
                overflow: hidden;
                cursor: pointer;
                img {
                    display: block;
                    width: 100%;
                    transform: scale(1);
                    transition: .5s;
                }
                .solution-item-bg {
                    position: absolute;
                    top: 72%;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.4) 70%);
                    transition: .5s;
                }
                .solution-item-box {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 0 11%;
                    transition: .5s;
                    p {
                        font-size: 14px;
                        line-height: 2;
                        color: #fff;
                    }
                    .solution-item-title {
                        font-size: 24px;
                        line-height: 1.5;
                    }
                    .solution-item-btn {
                        opacity: 0;
                        display: inline-block;
                        font-size: 14px;
                        color: #fff;
                        margin-top: 14px;
                        transition: .5s;
                        span {
                            color: #F39800;
                        }
                        &:hover {
                            color: #F39800;
                        }
                    }
                }
                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: .5s;
                    }
                    .solution-item-bg {
                        top: 0;
                        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.6) 70%);
                        transition: .5s;
                    }
                    .solution-item-box {
                        bottom: 35px;
                        transition: .5s;
                        .solution-item-btn {
                            opacity: 1;
                            transition: .5s;
                        }
                    }
                }
            }
            &>p{
                color: #ffffff;
                text-align: center;
                font-size: 24px;
                margin-top: 40px;
            }
        }
    }

    .new-list{
        overflow: hidden;
        margin: 0 -20px;
        li{
            float: left;
            list-style: none;
            width: 33.33%;
            padding: 0 20px;
            .news-item {
                cursor: pointer;
                background: #494b52;
                .news-img {
                    width: 100%;
                    padding-top: 62%;
                    background-size: cover;
                    background-position: center;
                }
                p {
                    text-align: center;
                    font-size: 16px;
                    line-height: 70px;
                    color: #fff;
                    margin: 0 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .company-box{
        overflow: hidden;
        background-image: url(../../assets/images/index-company-bg.jpg);
        background-size: cover;
        background-position: center;
        h2{
            color: #ffffff;
        }
        .company-div{
            width: 577px;
            padding: 66px 50px 50px 50px;
            background: rgba($color: #000000, $alpha: 0.5);
            float: right;
            .company-text{
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 38px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 6;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: justify;
            }

            .company-btn{
                width: 90px;
                background: #F39800;
                line-height: 24px;
                color: #fff;
                border: none;
                margin-top: 34px;
                cursor: pointer;
                font-size: 12px;
            }
        }
    }

    .swiper-video {
        position: relative;
        height: 100%;
        video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .swiper-video-txt {
            position: absolute;
            top: 0;
            left: 14%;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            p {
                color: #fff;
                font-size: 32px;
                font-weight: lighter;
            }
            .swiper-video-title {
                font-size: 36px;
                font-weight: normal;
                padding-bottom: 25px;
            }
            button {
                background-color: #F39800;
                color: #fff;
                font-size: 14px;
                border: none;
                padding: 7px 13px;
                margin-top: 50px;
                cursor: pointer;
            }
        }
    }
    .videoPlayer {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.7);
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        .videoPlayer-in {
            position: relative;
            width: 64%;
            background-color: #333;
            padding: 15px;
            video {
                display: block;
                width: 100%;
            }
            .videoPlayer-btn {
                display: block;
                position: absolute;
                top: -50px;
                right: -35px;
                width: 35px;
                height: 35px;
                background-image: url(../../assets/images/btn-close.png);
                cursor: pointer;
                border-radius: 50%;
                background-size: cover;
            }
        }
    }

    @media only screen and (max-width: 1440px) {
        .box {
            padding: 100px 0 50px;
            &.box1 {
                padding-bottom: 150px;
            }
            h1 {
                font-size: 36px;
                top: -56px;
            }
            h2 {
                font-size: 14px;
            }
        }
        .swiper-video {
            .swiper-video-txt {
                p {
                    font-size: 24px;
                }
                .swiper-video-title {
                    font-size: 28px;
                }
            }
        }
        .product-list {
            li {
                .product-list-box {
                    div {
                        left: 20%;
                        right: 20%;
                        p {
                            font-size: 12px;
                             &.product-list-title {
                                font-size: 20px;
                            }
                            &.product-list-label {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        .solution-list {
            li {
                .solution-item {
                    .solution-item-box {
                        padding: 0 8%;
                        p {
                            font-size: 12px;
                        }
                        .solution-item-title {
                            font-size: 12px;
                        }
                        .solution-item-btn {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .new-list {
            li {
                .news-item {
                    p {
                        font-size: 12px;
                        line-height: 50px;
                    }
                }
            }
        }
        .company-box {
            .company-div {
                .company-text {
                    font-size: 12px;
                }
            }
        }
    }


// 适配平板
@media screen and (max-width: 1100px) {
    .box{
        padding: 60px 0 60px;
        h1{
            font-size: 40px;
            top: -65px;
        }
        h2{
            font-size: 20px;
        }

        // .product-list{
        //     li{
        //          .product-list-box{
        //               div{
        //                   top: 38%;
        //                   p{
        //                       font-size: 18px;
        //                       letter-spacing: 12px;
        //                   }
        //                    p.product-list-title{
        //                        font-size: 28px;
        //                    }
        //               }
        //          }
        //     }
        // }
        .solution-list{
             li{
                padding: 0 6px;
                .solution-item{
                    .solution-item-box{
                        padding: 0 6%;
                        .solution-item-title{
                            font-size: 18px;
                        }
                        p{
                            // font-size: 14px;
                        }
                        .solution-item-btn{
                            // font-size: 14px;
                        }
                    }
                }
             }
        }
        .new-list{
            li{
                padding: 0 10px;
                .news-item{
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

// 移动端适配
@media screen and (max-width: 750px) {

}
</style>