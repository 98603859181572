<template>
    <div class="pagination" v-if="pages !== 1">
        <a href="javascript:;" @click="changePage(-3)" :class="{ disabled: currentPage === 1 }">首页</a>
        <a href="javascript:;" @click="changePage(-1)" v-if="currentPage !== 1">上一页</a>
        <span v-if="currentPage > 3">···</span>
        <a href="javascript:;" @click="changePage(item)" v-for="item in list" :key="item" :class="{ active: currentPage === item }">{{ item }}</a>
        <span v-if="currentPage < pages - 2">···</span>
        <a href="javascript:;" @click="changePage(-2)" v-if="currentPage !== pages">下一页</a>
        <a href="javascript:;" @click="changePage(-4)" :class="{ disabled: currentPage === pages }">末页</a>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue'

export default {
    name: 'Pagination',
    props: {
        total: { type: Number, default: 100 },
        pageSize: { type: Number, default: 10 }
    },
    setup (props, { emit, attrs }) {
        // attrs表示父组件传递的属性，但是props没有接收的属性，这种属性不是响应式的
        // 总页数
        let pages = ref(Math.ceil(props.total / props.pageSize))
        // 当前页码
        const currentPage = ref(attrs.pageNo || 1)
        // 动态计算页码列表
        const list = computed(() => {
            // 当父组件传递total的值发生变化时，计算属性会重新计算
            let pages = Math.ceil(props.total / props.pageSize)
            const result = []
            // 总页码小于等于5；大于5
            if (pages <= 5) {
                // 总页码小于等于5的情况
                for (let i = 1; i <= pages; i++) {
                    result.push(i)
                }
            } else {
                // 总页码大于5
                if (currentPage.value <= 2) {
                    // 左侧临界值
                    for (let i = 1; i <= 5; i++) {
                        result.push(i)
                    }
                } else if (currentPage.value >= pages - 1) {
                    // 右侧临界值
                    for (let i = pages - 4; i <= pages; i++) {
                        result.push(i)
                    }
                } else {
                    // 中间的状态
                    for (let i = currentPage.value - 2; i <= currentPage.value + 2; i++) {
                        result.push(i)
                    }
                }
            }
            return result
        })
        // 控制上一页和下一页变化
        const changePage = (type) => {
            if (type === -1) {
                if (currentPage.value === 1) return;
                currentPage.value -= 1;
            } else if (type === -2) {
                if (currentPage.value === pages.value) return;
                currentPage.value += 1;
            } else if (type === -3) {
                if (currentPage.value === 1) return;
                currentPage.value = 1;
            } else if (type === -4) {
                if (currentPage.value === pages.value) return;
                currentPage.value = pages.value;
            } else {
                currentPage.value = type;
            }
            emit('change-page', currentPage.value)
        }
        watch(props, (newProps) => {
            pages.value = Math.ceil(newProps.total / newProps.pageSize);
        });
        return { list, currentPage, pages, changePage }
    }
}
</script>
<style scoped lang="scss">
.pagination {
    display: flex;
	align-items: center;
    justify-content: flex-end;
    padding: 30px 0;
    > a {
		display: inline-block;
		padding: 6px 13px;
		border: 1px solid #E68C02;
		border-radius: 5px;
		margin-left: 10px;
		text-decoration: none;
		color: #000;
		font-size: 14px;
		line-height: 1.5;
		min-width: 35px;
		text-align: center;
		box-sizing: border-box;
        &:hover {
            background: rgba(230,140,2,.1);
        }
        &.active {
            background: #E68C02;
            color: #fff;
            border-color: #E68C02;
        }
        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
            &:hover {
                color: #333;
            }
        }
    }
    > span {
        margin-left: 10px;
    }
}
</style>
