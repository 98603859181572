<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/aboutus/banner_contact.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">联系我们</h2>
                <div class="contact_map">
                    <div id="container"></div>
                </div>
                <ul class="contact_icon_list">
                    <li>
                        <img src="@/assets/aboutus/icon_contact_01.png"/>
                        <p>筑泰（深圳）信息技术有限公司</p>
                    </li>
                    <li>
                        <img src="@/assets/aboutus/icon_contact_02.png"/>
                        <p>400-816-6630</p>
                    </li>
                    <li>
                        <img src="@/assets/aboutus/icon_contact_03.png"/>
                        <p>深圳市福田区梅林街道孖岭社区凯丰路10号翠林大厦7层7F042</p>
                    </li>
                    <li>
                        <img src="@/assets/aboutus/icon_contact_04.png"/>
                        <p>xuhui@zoomtech.cc</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="smbox" style="background-color: #F7F7F7;">
            <div class="wrap_box">
                <h2 class="smbox_title contact_title">申请合作<p>请填写信息，我们将尽快与您联系，感谢您的支持！</p></h2>
                <div class="form-box">
                    <div class="form-item">
                        <div class="form-label"><span>*</span>姓名</div>
                        <input type="text" v-model="name">
                    </div>
                    <div class="form-item">
                        <div class="form-label"><span>*</span>职位</div>
                        <input type="text" v-model="position">
                    </div>
                    <div class="form-item">
                        <div class="form-label"><span>*</span>电话/手机</div>
                        <input type="text" v-model="phone">
                    </div>
                    <div class="form-item">
                        <div class="form-label"><span>*</span>公司名称</div>
                        <input type="text" v-model="companyName">
                    </div>
                    <div class="form-item">
                        <div class="form-label">公司网址</div>
                        <input type="text" v-model="companyWeb">
                    </div>
                    <div class="form-item">
                        <div class="form-label">补充说明</div>
                        <textarea v-model="remark"></textarea>
                    </div>
                    <div class="form-btn">
                        <button type="button" @click="submit">提交</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import API from "@/api/index.js";

export default ({
    components: {
        Breadcrumb
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '关于我们',
            }, {
                txt: '联系我们',
            }],
            name: '',
            position: '',
            phone: '',
            companyName: '',
            companyWeb: '',
            remark: '',
        }
    },
    mounted() {
        let map = new window.BMapGL.Map('container');
        let point = new window.BMapGL.Point(114.070923, 22.575293);
        map.centerAndZoom(point, 18);
        map.enableScrollWheelZoom(true);
        let marker = new window.BMapGL.Marker(point); // 创建标注   
        map.addOverlay(marker);                       // 将标注添加到地图中
    },
    methods: {
        async submit() {
            if(!this.name.replace(/(^\s*)|(\s*$)/g, "")) {
                this.$message({ text: '请输入姓名', type: 'error' })
                return
            }
            if(!this.position.replace(/(^\s*)|(\s*$)/g, "")) {
                this.$message({ text: '请输入职位', type: 'error' })
                return
            }
            if(!this.phone.replace(/(^\s*)|(\s*$)/g, "")) {
                this.$message({ text: '请输入电话/手机号码', type: 'error' })
                return
            }
            if(!this.companyName.replace(/(^\s*)|(\s*$)/g, "")) {
                this.$message({ text: '请输入公司名称', type: 'error' })
                return
            }
            let regPhone = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
            let regTel = /^(0\d{2,3})-?(\d{7,8})$/;
            if(!(regPhone.test(this.phone) || regTel.test(this.phone))) {
                this.$message({ text: '请输入正确的电话/手机号码', type: 'error' })
                return;
            }

            await API.home.saveVisitorn({
                name: this.name,
                position: this.position,
                phone: this.phone,
                companyName: this.companyName,
                companyWeb: this.companyWeb,
                remark: this.remark,
                type:'筑泰信息'
            });
            this.$message({ text: '提交成功', type: 'success' })
        },
    }
})
</script>

<style lang="scss" scoped>
.contact_map {
    padding-top: 85px;
    height: 558px;
    img {
        display: block;
        width: 100%;
    }
    div {
        width: 100%;
        height: 100%;
    }
}
.contact_icon_list {
    display: flex;
    padding: 100px 80px 50px;
    li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px 34px;
        img {
            display: block;
            width: 100px;
            margin-bottom: 30px;
        }
        p {
            color: #000;
            line-height: 2;
        }
    }
}
.contact_title {
    &.smbox_title {
        margin: 45px 0 100px;
        p {
            position: absolute;
            top: 45px;
            left: 0;
            width: 100%;
            color: #000;
            -webkit-text-fill-color: #D2D2D2;
            font-size: 14px;
            text-align: center;
            font-weight: normal;
        }
        &::after {
            bottom: -55px;
        }
    }
}
.form-box {
    padding: 20px 0 60px;
    width: 47%;
    margin: 0 auto;
    max-width: 600px;
    .form-item {
        font-size: 16px;
        color: #010101;
        padding: 10px 0;
        .form-label {
            margin-bottom: 14px;
            span {
                color: #f00;
                margin-right: 10px;
            }
        }
        input {
            font-size: 16px;
            color: #010101;
            border: 1px solid #D2D2D2;
            width: 100%;
            height: 40px;
            background-color: transparent;
            padding: 0 10px;
            box-sizing: border-box;
        }
        textarea {
            font-size: 16px;
            color: #010101;
            border: 1px solid #D2D2D2;
            width: 100%;
            height: 60px;
            background-color: transparent;
            padding: 10px;
            box-sizing: border-box;
            resize: none;
        }
    }
    .form-btn {
        text-align: right;
        padding-top: 40px;
        button {
            width: 120px;
            height: 40px;
            border: none;
            background-color: #00a0e9;
            line-height: 40px;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            &:hover {
                background-color: #0099df;
            }
        }
    }
}
</style>
