<template>
	<Header />
	<div class="main">
		<router-view></router-view>
	</div>
	<Footer></Footer>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
	name: 'App',
	components: {
		Header, Footer
	}
}
</script>

<style>
#app {

}
</style>
