<template>
    <div>
        <div class="page_banner">
            <img src="../../assets/product/banner_beijia.jpg"/>
        </div>
        <!-- <div class="page_route">
            <div class="page_center">
                您现在的位置：首页/产品中心/泰极ZT20 5G多功能宽窄带融合终端 —— 智慧融合 通信无忧
            </div>
        </div> -->
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>

        <div class="pro_box bg_black">
            <div class="page_center">
                <h2 class="page_title">
                    行业背景
                </h2>
                <p class="content_center">
                          筑视 ZT30 AR智能眼镜是一款采用视觉边缘计算技术，集智能识别、PDT对讲、多媒体指挥调度等功能于一体的智能通信产品。<br>
产品基于人体工程学设计，整体重量不超过80g。产品基于阵列光波导技术打造了1.5mm超薄波导镜片，眼镜透光度可达82%；配置了800万像素自动对焦相机及1W喇叭和MIC，拥有4K专业级摄像能力，1920*1080P高清录像，可满足不同场景的多样化需求。
                </p>
            </div>
        </div>

        <div class="pro_box no_padding">
            <div class="page_center" style="display: flex;flex-direction: row-reverse;">
                <div class="pro_right">
                    <img src="../../assets/product/pro_beijia_01.png"/>
                </div>
                <div class="pro_left pro_content" style="flex: 1;">
                    <h3 style="margin-top:1rem">PDT专业对讲</h3>
                    <p>能够高效利用资源、降低使用成本、提高综合效率，<br>
                        自主安全的加密技术符合国家标准。此外，PDT对讲具有功能强、平滑过渡、<br>
                        机密机制、国产语音编码、大区制、成本低的特点。
                            </p>
                </div>
            </div>
        </div>

        <div class="pro_box bg_black">
            <div class="page_center">
                <div class="pro_left">
                    <img src="../../assets/product/pro_beijia_02.jpg"/>
                </div>
                <div class="pro_right pro_content" style="width: 500px">
                    <h3 style="margin-top: 80px">多功能对讲 通讯稳定</h3>
                    <p>通过连接多功能融合终端产品,<br>
                        结合数字语音压缩技术和硬件降噪技术，实现语音清晰、<br>
                        高效频谱效率、通信距离远、抗干扰能力强等特点，<br>
                        在各种嘈杂、复杂的环境下仍能清晰通话。
                            </p>
                </div>
            </div>
        </div>

        <div class="pro_box pro_box03">
            <div class="pro_img" style="margin-left: 12%;"><img src="../../assets/product/pro_beijia_03_l.png"/></div>
            
            <div class="pro_center pro_content">
                <h3>IP68 军工级防护</h3>
                <p>严谨的工艺结构设计，产品具备IP68最高级别防水防尘等级，军工级别防护，<br>
                为严酷的复杂工作环境提供最可靠的使用保障。
                        </p>
            </div>
            <div class="pro_img" style="margin-right: 12%;"><img src="../../assets/product/pro_beijia_03_r.png"/></div>
        </div>

        <div class="pro_box_lbg pro_box04">
            <img class="pro_bg" src="../../assets/product/pro_beijia_04.png"/>
            <div class="page_center">
                <div style="display: flex;justify-content: center;width: 900px;">
                    <ul>
                        <li>
                            <h4>8000mAh</h4>
                            <p>标配电池</p>
                        </li>
                        <li>
                            <h4>24小时</h4>
                            <p>高功能工作时长</p>
                        </li>
                        <li>
                            <h4>24W-40W</h4>
                            <p>急速快充</p>
                        </li>
                    </ul>
                </div>
                <div class="pro_content">
                    <h3>超大容量电池</h3>
                    <p>标配8000mAh电池，长达24小时高功能工作时长，支持24W-40W急速快充，快速“回血”，<br>
                完美续航，并可作为充电设备，为其他设备反向充电。
                            </p>
                </div>
            </div>
            
        </div>

        <div class="pro_box_lbg pro_box05">
            <img class="pro_bg" src="../../assets/product/pro_beijia_05.png"/>
            <div class="page_center">
                <ul>
                    <li>
                        <h4>90°</h4>
                        <p>旋转式机身设计</p>
                    </li>
                    <li>
                        <h4>32.5MM</h4>
                        <p>机身</p>
                    </li>
                    <li>
                        <h4>2W</h4>
                        <p>大功率高品质喇叭</p>
                    </li>
                </ul>
                <div class="pro_content">
                    <h3>旋转机身 高清音质</h3>
                    <p>90°旋转式机身设计，32.5mm机身，搭载2W大功率高品质喇叭，声音响亮。AI消噪技术减少通话环境噪音，还原高保真语音效果，让你感受犹如耳边的近距离通话。
                            </p>
                </div>
            </div>
            
        </div>

        <div class="pro_box_lbg pro_box06">
            <img class="pro_bg" src="../../assets/product/pro_beijia_06.jpg"/>
        </div>

        <div class="pro_box pro_box07 bg_black">
            <div class="pro_content">
                <h3>多样连接 实现扩展能力</h3>
                <p>搭载M6扩展接口及Tpye-c数据接口，可扩展与手机实现双向通信，<br>
连接AR智能眼镜作为视觉边缘计算盒子和通讯设备使用，产品广泛应用于公安、司法、工业制造等行业。
                        </p>
            </div>  
            <div class="pro_data">
                <h3>产品参数</h3>
                <table cellpadding="0" cellspacing="0">
                    <tr>
                        <td rowspan="3">通信协议标准</td>
                        <td>PDT数字集群GA/T1056、1057、1058、1059</td>
                    </tr>
                    <tr>
                        <td>DMRTier2/Tier3数字集群ETSI-TS102361-1、-2、-3、-4</td>
                    </tr>
                    <tr>
                        <td>模拟集群MPT 1327/MPT 1343</td>
                    </tr>
                    <tr>
                        <td>调制方式</td>
                        <td>FM、4FSK</td>
                    </tr>
                    <tr>
                        <td>信道间隔</td>
                        <td>12.5KHZ/20KHZ/25KHZ</td>
                    </tr>
                    <tr>
                        <td rowspan="2">射频功率输出</td>
                        <td>VHF高功率：5W；VHF低功率：1W</td>
                    </tr>
                    <tr>
                        <td>UHF高功率：4W；UHF低功率：1W</td>
                    </tr>
                    <tr>
                        <td>数据业务</td>
                        <td>状态消息，长短消息，包数据业务</td>
                    </tr>
                    <tr>
                        <td>语音业务</td>
                        <td>个呼、组呼、广播呼叫、迟后进入、主叫识别、紧急呼叫、DGNA动态重组</td>
                    </tr>
                    <tr>
                        <td>电池</td>
                        <td>8000mAh</td>
                    </tr>
                    <tr>
                        <td>外型尺寸</td>
                        <td>175*87*32.5mm(高*宽*厚)</td>
                    </tr>
                    <tr>
                        <td>工作温度</td>
                        <td>-20℃ ~ +60℃</td>
                    </tr>
                    <tr>
                        <td>存储温度</td>
                        <td>-40℃ ~ +85℃</td>
                    </tr>
                    <tr>
                        <td>电源接口</td>
                        <td>连接器(Panasonic AYF534035)</td>
                    </tr>
                    <tr>
                        <td>防护等级</td>
                        <td>IP68</td>
                    </tr>
                </table>
            </div>   
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '产品中心',
            }, {
                txt: '泰极ZT20 5G多功能宽窄带融合终端 —— 智慧融合 通信无忧',
            }],
        }
    }
}
</script>
<style scoped lang="scss">

// .pro_box{
//   margin: 0px auto;
//   padding: 0px 0;
// }

.pro_content{
  h3{
    font-size: 24px;
    background-image:-webkit-linear-gradient(bottom,#D65D00,#F39800); 
    -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent; 
    // margin-top: 240px;
    
    &::before{
      display: none;
    }
  }
  p{
    line-height: 30px;
    margin-top: 20px;
    font-size: 14px;
  }
}
 
.pro_right{
  img{
      float: left;
  }
}

.pro_box03{
    padding: 0;
    display: flex;
    align-items: center;
    .pro_content{
        flex: 1;
        text-align: center;
        h3 {
            margin: 0;
        }
        p {
            margin-top: 40px;
        }
    }
    .pro_img {
        width: 20%;
        img{
            width: 100%;
            display: block;
        }
    }
    
}



.pro_box04{
    color: #fff;
    .page_center{
        position: absolute;
        top: 32%;
        width: 1280px;
        left: 50%;
        transform: translate(-60%, 0);
        ul{
            // 
            overflow: hidden;
            // position: absolute;
            float: left;
            li{
                float: left;
                list-style: none;
                padding: 0 45px;
                text-align: center;
                h4{
                    background-image:-webkit-linear-gradient(bottom,#D65D00,#F39800); 
                    -webkit-background-clip:text; 
                    -webkit-text-fill-color:transparent; 
                    font-size: 24px;
                    margin: 0;
                }
                p{
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 15px;
                }
            }
        }

        .pro_content{
            float: left;
            // position: absolute;
            text-align: center;
            width: 900px;
            // top: 54%;
            margin-top: 30px;
        }
    }
    

    
}

.pro_box05{
    .page_center{
        position: absolute;
        top: 30%;
        width: 1280px;
        left: 50%;
        transform: translate(-50%, 0);
        ul{
            // top: 30%;
            overflow: hidden;
            // position: absolute;
            float: left;
            li{
                float: left;
                list-style: none;
                padding: 0 90px 0 0;
                text-align: center;
                h4{
                    background-image:-webkit-linear-gradient(bottom,#D65D00,#F39800); 
                    -webkit-background-clip:text; 
                    -webkit-text-fill-color:transparent; 
                    font-size: 24px;
                    margin: 0;
                }
                p{
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 20px;
                }
            }
        }

        .pro_content{
            // position: absolute;
            width: 700px;
            // top: 50%;
            margin-top: 30px;
            float: left;
        }
    }
    
}

.pro_box07{
    .pro_content{
        width: 100%;
        text-align: center;
    }
}

// 适配平板
@media screen and (max-width: 1100px) {
    .pro_right{
        img{
            width: 3rem;
        }
    }
    .pro_left{
        img{
            width: 2.6rem;
        }
    }

    .pro_box04{
        .pro_content{
            h3{
                margin-top: 20px;
            }
        }
    }
    
}
</style>