<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/service/banner_service.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">服务理念</h2>
                <p class="smbox_txt">筑泰向用户提供专业化、多元化、产品化的服务，一直秉承以客户为中心，以满足客户的需求，超越客户期待为目标，为客户提供满意和人性化的服务。</p>
            </div>
        </div>
        <div class="smbox bg_gray">
            <div class="wrap_box">
                <h2 class="smbox_title">服务特点</h2>
                <ul class="service_img_list">
                    <li>
                        <div class="service_img_box">
                            <img src="@/assets/service/img_service_01.jpg"/>
                            <div>
                                <p class="service_img_label">专业</p>
                                <p>专业的售前、售中、售后技术服务团队</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="service_img_box">
                            <img src="@/assets/service/img_service_02.jpg"/>
                            <div>
                                <p class="service_img_label">规范</p>
                                <p>规范的服务流程，规范的质量保障体系</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="service_img_box">
                            <img src="@/assets/service/img_service_03.jpg"/>
                            <div>
                                <p class="service_img_label">快捷</p>
                                <p>技术服务和售后服务中心覆盖全国，7*24小时快速响应</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">技术服务</h2>
                <ul class="service_icon_list">
                    <li>
                        <img src="@/assets/service/icon_service_01.png"/>
                        <p class="service_icon_label">售前支持</p>
                        <p>专业的业务、产品、技术等专业人员，深入了解客户需求，定制专属行业解决方案。</p>
                    </li>
                    <li>
                        <img src="@/assets/service/icon_service_02.png"/>
                        <p class="service_icon_label">培训服务</p>
                        <p>提供免费的产品使用培训、系统使用培训、管理员系统升级及维护培训、系统安装部署培训等。</p>
                    </li>
                    <li>
                        <img src="@/assets/service/icon_service_03.png"/>
                        <p class="service_icon_label">现场服务</p>
                        <p>我们拥有完善的技术服务、运维服务体系和专业的客服团队，及时为客户提供现场服务。</p>
                    </li>
                    <li>
                        <img src="@/assets/service/icon_service_04.png"/>
                        <p class="service_icon_label">增值服务</p>
                        <p>为最快速的解决用户产品运行中的问题，筑泰提供专业的技术工程师现场驻点服务。</p>
                    </li>
                    <li>
                        <img src="@/assets/service/icon_service_05.png"/>
                        <p class="service_icon_label">售后服务</p>
                        <p>遍及全国的售后服务体系，提供包括故障快速响应、定期巡检维护、主动问题监测的全流程服务。</p>
                    </li>
                    <li>
                        <img src="@/assets/service/icon_service_06.png"/>
                        <p class="service_icon_label">技术支持</p>
                        <p>专业的技术人员现场安装调试，并负责全面培训，包括产品操作、编辑程序、调试等，以及其他注意事项。</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="img_fullwidth">
            <img src="@/assets/service/img_service_bg.jpg"/>
        </div>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">全国售后服务网络</h2>
                <p class="smbox_txt" style="padding-top: 60px;">重庆市、四川、西藏、广西、云南、青海、甘肃、新疆、福建、广东、海南、辽宁、吉林、黑龙江、内蒙古、山东、江苏、湖北、安徽、湖南、江西、上海市、浙江、北京市、天津市、陕西、宁夏、河北、山西、河南、贵阳。</p>
                <div class="service_map">
                    <!-- <img src="@/assets/service/map_service.png"/> -->
                    <div id="myEcharts" :style="{ width: '900px', height: '900px', margin: '0 auto' }"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import { onMounted, onUnmounted } from 'vue'
import * as echarts from 'echarts'
import chinaMap from "./china.json";
export default ({
    components: {
        Breadcrumb
    },
    setup() {
        let chart;

        onMounted(() => {
            initChart();
        });

        onUnmounted(() => {
            chart.dispose();
        });

        //  地图数据
        const mapData = {
            citys: [{
                name: '香港',
                value: [114.173355, 22.320048],
                label: {
                    position: 'bottom',
                    fontSize: 12,
                },
            }, {
                name: '澳门',
                value: [113.549090, 22.198951],
                label: {
                    position: 'left',
                    fontSize: 12,
                },
            }, {
                name: '深圳',
                value: [114.073586, 22.563735],
                label: {
                    position: 'right',
                },
                itemStyle: {
                    normal: {
                        color: '#e61e26',
                    },
                },
            }, {
                name: '北京',
                value: [116.548276, 40.505607],
                itemStyle: {
                    normal: {
                        color: '#e61e26',
                    },
                },
            }, {
                name: '黑龙江',
                value: [128.248957, 47.003524],
            }, {
                name: '吉林',
                value: [126.850763, 43.469554],
            }, {
                name: '辽宁',
                value: [122.509001, 41.23185],
            }, {
                name: '内蒙古',
                value: [111.606599, 41.765436],
            }, {
                name: '新疆',
                value: [85.346461, 41.287382],
            }, {
                name: '西藏',
                value: [87.848493, 31.368866],
            }, {
                name: '甘肃',
                value: [96.300048, 40.147631],
            }, {
                name: '青海',
                value: [96.164072, 35.861497],
            }, {
                name: '宁夏',
                value: [106.098612, 37.637999],
            }, {
                name: '四川',
                value: [103.007866, 30.480657],
            }, {
                name: '重庆',
                value: [106.466558, 29.519841],
            }, {
                name: '云南',
                value: [101.462493, 24.175736],
            }, {
                name: '天津',
                value: [117.548926, 38.77865],
                label: {
                    position: 'right',
                },
            }, {
                name: '山东',
                value: [118.535185, 36.398799],
            }, {
                name: '山西',
                value: [111.69139, 35.981218],
            }, {
                name: '河北',
                value: [114.855725, 37.168328],
            }, {
                name: '河南',
                value: [113.604709, 34.043898],
            }, {
                name: '陕西',
                value: [108.306288, 33.798493],
            }, {
                name: '贵阳',
                value: [106.760915, 26.912693],
            }, {
                name: '江苏',
                value: [119.123898, 33.552376],
            }, {
                name: '安徽',
                value: [117.284168, 31.431989],
            }, {
                name: '上海',
                value: [121.552341, 31.24249],
                label: {
                    position: 'right',
                },
            }, {
                name: '浙江',
                value: [120.006968, 29.262044],
            }, {
                name: '湖北',
                value: [112.353693, 31.368866],
            }, {
                name: '湖南',
                value: [111.544212, 27.701566],
            }, {
                name: '江西',
                value: [115.591617, 27.963258],
            }, {
                name: '福建',
                value: [117.872882, 25.918722],
            }, {
                name: '广东',
                value: [113.678298, 23.769994],
            }, {
                name: '广西',
                value: [109.042179, 23.769994],
                label: {
                    position: 'bottom',
                },
            }, {
                name: '海南',
                value: [109.704482, 19.225915],
                label: {
                    position: 'bottom',
                },
            }, {
                name: '台湾',
                value: [120.974272, 23.714143],
            }],
            moveLines: [{
                coords: [[114.073586, 22.563735], [116.548276, 40.505607]],
                fromName: "深圳",
                toName: "北京"
            }, {
                coords: [[114.073586, 22.563735], [128.248957, 47.003524]],
                fromName: "深圳",
                toName: "黑龙江"
            }, {
                coords: [[114.073586, 22.563735], [126.850763, 43.469554]],
                fromName: "深圳",
                toName: "吉林"
            }, {
                coords: [[114.073586, 22.563735], [122.509001, 41.23185]],
                fromName: "深圳",
                toName: "辽宁"
            }, {
                coords: [[114.073586, 22.563735], [111.606599, 41.765436]],
                fromName: "深圳",
                toName: "内蒙古"
            }, {
                coords: [[114.073586, 22.563735], [85.346461, 41.287382]],
                fromName: "深圳",
                toName: "新疆"
            }, {
                coords: [[114.073586, 22.563735], [87.848493, 31.368866]],
                fromName: "深圳",
                toName: "西藏"
            }, {
                coords: [[114.073586, 22.563735], [96.300048, 40.147631]],
                fromName: "深圳",
                toName: "甘肃"
            }, {
                coords: [[114.073586, 22.563735], [96.164072, 35.861497]],
                fromName: "深圳",
                toName: "青海"
            }, {
                coords: [[114.073586, 22.563735], [106.098612, 37.637999]],
                fromName: "深圳",
                toName: "宁夏"
            }, {
                coords: [[114.073586, 22.563735], [103.007866, 30.480657]],
                fromName: "深圳",
                toName: "四川"
            }, {
                coords: [[114.073586, 22.563735], [106.466558, 29.519841]],
                fromName: "深圳",
                toName: "重庆"
            }, {
                coords: [[114.073586, 22.563735], [101.462493, 24.175736]],
                fromName: "深圳",
                toName: "云南"
            }, {
                coords: [[114.073586, 22.563735], [117.548926, 38.77865]],
                fromName: "深圳",
                toName: "天津"
            }, {
                coords: [[114.073586, 22.563735], [118.535185, 36.398799]],
                fromName: "深圳",
                toName: "山东"
            }, {
                coords: [[114.073586, 22.563735], [111.69139, 35.981218]],
                fromName: "深圳",
                toName: "山西"
            }, {
                coords: [[114.073586, 22.563735], [114.855725, 37.168328]],
                fromName: "深圳",
                toName: "河北"
            }, {
                coords: [[114.073586, 22.563735], [113.604709, 34.043898]],
                fromName: "深圳",
                toName: "河南"
            }, {
                coords: [[114.073586, 22.563735], [108.306288, 33.798493]],
                fromName: "深圳",
                toName: "陕西"
            }, {
                coords: [[114.073586, 22.563735], [106.760915, 26.912693]],
                fromName: "深圳",
                toName: "贵阳"
            }, {
                coords: [[114.073586, 22.563735], [119.123898, 33.552376]],
                fromName: "深圳",
                toName: "江苏"
            }, {
                coords: [[114.073586, 22.563735], [117.284168, 31.431989]],
                fromName: "深圳",
                toName: "安徽"
            }, {
                coords: [[114.073586, 22.563735], [121.552341, 31.24249]],
                fromName: "深圳",
                toName: "上海"
            }, {
                coords: [[114.073586, 22.563735], [120.006968, 29.262044]],
                fromName: "深圳",
                toName: "浙江"
            }, {
                coords: [[114.073586, 22.563735], [112.353693, 31.368866]],
                fromName: "深圳",
                toName: "湖北"
            }, {
                coords: [[114.073586, 22.563735], [111.544212, 27.701566]],
                fromName: "深圳",
                toName: "湖南"
            }, {
                coords: [[114.073586, 22.563735], [115.591617, 27.963258]],
                fromName: "深圳",
                toName: "江西"
            }, {
                coords: [[114.073586, 22.563735], [117.872882, 25.918722]],
                fromName: "深圳",
                toName: "福建"
            }, {
                coords: [[114.073586, 22.563735], [109.042179, 23.769994]],
                fromName: "深圳",
                toName: "广西"
            }, {
                coords: [[114.073586, 22.563735], [109.704482, 19.225915]],
                fromName: "深圳",
                toName: "海南"
            }]
        };
        
        // 基础配置一下Echarts
        function initChart() {
            echarts.registerMap("china", { geoJSON: chinaMap });
            chart = echarts.init(document.getElementById("myEcharts"), null, { renderer: 'canvas' });
            // 把配置和数据放这里
            chart.setOption({
                title: {
                    show: false
                },
                legend: {
                    show: false,
                },
                geo: {
                    nameMap: {
                        China: '中国',
                    },
                    map: 'china',
                    label: {
                        emphasis: {
                            show: false,
                        },
                    },
                    zoom: 1.2,
                    itemStyle: {
                        normal: {
                            borderColor: '#a3a3a4', //区域边框颜色
                            areaColor: '#eeeeef', //区域颜色
                            borderWidth: 0.5, //区域边框宽度
                        },
                        emphasis: {
                            borderColor: '#0091e8',
                            areaColor: '#eeeeef', //区域颜色
                            borderWidth: 1,
                        },
                    },
                },
                series: [{
                    name: '地点',
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    zlevel: 2,
                    rippleEffect: {
                        brushType: 'stroke',
                    },
                    label: {
                        show: true,
                        formatter: '{b}',
                        position: 'top',
                        fontSize: 16,
                        color: '#000'
                    },
                    symbolSize: 8,
                    showEffectOn: 'render',
                    itemStyle: {
                        normal: {
                            color: '#f0861b'
                        },
                    },
                    data: mapData.citys,
                }, {
                    name: '线路',
                    type: 'lines',
                    coordinateSystem: 'geo',
                    zlevel: 2,
                    zoom: 1,
                    large: true,
                    effect: {
                        show: true,
                        constantSpeed: 30,
                        symbol: 'pin',
                        symbolSize: 10,
                        trailLength: 0,
                    },
                    lineStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#f0861b',
                            }, {
                                offset: 1,
                                color: '#e61e26',
                            }], false),
                            width: 1,
                            opacity: 0.6,
                            curveness: 0.1,
                        },
                    },
                    data: mapData.moveLines,
                }],
            });
            window.onresize = function() {
                //自适应大小
                chart.resize();
            };
        }

        return { initChart };
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '服务支持',
            }]
        }
    }
})
</script>

<style lang="scss" scoped>
.service_img_list {
    display: flex;
    padding: 60px 0 10px;
    justify-content: center;
    li {
        display: block;
        padding: 15px;
        .service_img_box {
            width: 298px;
            position: relative;
            img {
                display: block;
                width: 100%;
            }
            div {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                padding: 0 30px;
                background-color: rgba(0,0,0,.5);
                p {
                    color: #fff;
                    font-size: 18px;
                    line-height: 2;
                    text-align: center;
                }
                .service_img_label {
                    font-size: 20px;
                    padding-bottom: 35px;
                }
            }
            &:hover {
                div {
                    display: flex;
                }
            }
        }
    }
}
.service_icon_list {
    display: flex;
    flex-wrap: wrap;
    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.33333%;
        padding: 50px 45px;
        img {
            width: 84px;
        }
        p {
            color: #010101;
            font-size: 14px;
            line-height: 2;
            text-align: center;
        }
        .service_icon_label {
            font-size: 20px;
            font-weight: bold;
            padding: 10px 0 35px;
        }
    }
}
.service_map {
    padding: 100px 0 80px;
    img {
        max-width: 967px;
        display: block;
        width: 100%;
        margin: 0 auto;
    }
}
</style>
