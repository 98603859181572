<template>
    <div>
        <div class="img_fullwidth">
            <img src="@/assets/aboutus/banner_join.jpg"/>
        </div>
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="smbox">
            <div class="wrap_box">
                <h2 class="smbox_title">招聘职位</h2>
                <div class="joinus_img">
                    <img src="@/assets/aboutus/img_join.jpg" style="border-radius: 6px;" />
                </div>
                <div class="joinus_txtbox">
                    <div class="smbox_txt" v-for="(item, index) in recruitInfo" :key="index">
                        <p>【{{item.position}}】</p>
                        <div class="joinus_positionDesc" v-html="item.positionDesc"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import API from "@/api/index.js";
import { ref } from 'vue'

export default ({
    components: {
        Breadcrumb
    },
    setup() {
        const recruitInfo = ref({});

        const loadInfo = async () => {
            let res = await API.home.showRecruitInfo({
                pageNo: 0,
                pageSize: 1000
            });
            recruitInfo.value = res.content;
        }
        
        return {
            recruitInfo,
            loadInfo
        }
    },
    data() {
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '关于我们',
            }, {
                txt: '加入我们',
            }]
        }
    },
    mounted() {
        this.loadInfo();
    }
})
</script>

<style lang="scss" scoped>
.joinus_img {
    padding-top: 110px;
    img {
        display: block;
        width: 100%;
    }
}
.joinus_txtbox {
    padding: 70px 0 40px;
    .smbox_txt {
        text-align: left;
        padding: 50px 0;
        border-top: 2px dashed #cbcbcb;
        &:first-child {
            border-top: none;
        }
    }
}
</style>
