<template>
    <div>
        <div class="page_banner">
            <img src="../../assets/product/banner_emm.jpg"/>
        </div>
        <!-- <div class="page_route">
            <div class="page_center">
                您现在的位置：首页/产品中心/筑泰防务云移动终端安全管控平台 —— 管控无序 构建有序
            </div>
        </div> -->
        <!-- 面包屑 -->
        <Breadcrumb :list="breadcrumb"></Breadcrumb>
        <div class="pro_box">
            <div class="page_center">
                <h2 class="page_title">
                    行业背景
                </h2>
                <p class="content_center">
                          简称”EMM”，帮助企业将IT管理能力从传统PC延伸至移动设备，<br>
                            解决企业智能终端统一配置、统一更新、设备丢失、数据泄漏等问题。从MDM移动设备管理、MAM移动应用管理、MCM移动文档管理三大基础需求出发，<br>
                            为企业提供全方位解决方案，打造高效和安全的移动办公环境。目前EMM已满足统信服务器操作系统V20兼容认证要求。
                </p>
                <ul class="product_icon_list">
                    <li>
                        <img src="../../assets/product/emm_icon01.png"/>
                        <p>精确的<br>数据统计</p>
                    </li>
                    <li>
                        <img src="../../assets/product/emm_icon02.png"/>
                        <p>全面的<br>管理能力</p>
                    </li>
                    <li>
                        <img src="../../assets/product/emm_icon03.png"/>
                        <p>完备的<br>安全体系</p>
                    </li>
                    <li>
                        <img src="../../assets/product/emm_icon04.png"/>
                        <p>灵活的<br>合规管理</p>
                    </li>
                    <li>
                        <img src="../../assets/product/emm_icon05.png"/>
                        <p>多系统<br>兼容模式</p>
                    </li>
                    <li>
                        <img src="../../assets/product/emm_icon06.png"/>
                        <p>多终端<br>设备支持</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="pro_box bg_gray">
            <div class="page_center">
                <div class="pro_left">
                    <img src="../../assets/product/pro_emm_01.png"/>
                </div>
                <div class="pro_right pro_content">
                    <h3>移动设备管理</h3>
                    <p>从设备准备、使用和淘汰的各个环节，提供完整的生命周期管理。包括用户管理、设备端口管理、配置管理、安全管理、终端状态管理等，帮助企业应对移动化进程中设备分发、部署、配置、管理困难等问题。
    </p>
                </div>
            </div>
        </div>

        <div class="pro_box">
            <div class="page_center">
                <div class="pro_right">
                    <img src="../../assets/product/pro_emm_02.png"/>
                </div>
                <div class="pro_left pro_content">
                    <h3>终端安全管理</h3>
                    <p>对通过锁屏、定位、轨迹跟踪、关机、设备限制、违规等功能实现终端安全管控，终端采用“人”“证”“机”“卡”绑定形式，一旦设备遗失马上启动定位、远程擦除数据、恢复出厂设置等操作，防止因终端丢失造成的信息泄露。
    </p>
                </div>
            </div>
        </div>

        <div class="pro_box bg_gray">
            <div class="page_center">
                <div class="pro_left">
                    <img src="../../assets/product/pro_emm_03.png"/>
                </div>
                <div class="pro_right pro_content">
                    <h3>移动内容管理</h3>
                    <p>企业文档远程自上而下地对移动终端自动下发，通过移动终端预览、下载、浏览文档内容，实现企业内部文件移动管理，并设置管理策略，防止企业内部文件和信息外泄。
    </p>
                </div>
            </div>
        </div>

        <div class="pro_box">
            <div class="page_center">
                <div class="pro_right">
                    <img src="../../assets/product/pro_emm_04.png"/>
                </div>
                <div class="pro_left pro_content">
                    <h3>移动应用管理</h3>
                    <p>构建高效的移动应用安全管理系统，实现移动应用获取、分发、管控、跟踪的全流程管理，禁止不合规应用，实现静默安装、按需限制应用功能，可设置围栏在规定范围允许开启应用，并对应用安装情况进行统计分析。
    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    components: {
        Breadcrumb
    },
    data(){
        return {
            breadcrumb: [{
                txt: '首页',
                url: '/'
            }, {
                txt: '产品中心',
            }, {
                txt: '筑泰防务云移动终端安全管控平台 —— 管控无序 构建有序',
            }],
        }
    }
}
</script>
